<template>
  <b-form-group
    :class="`forms-images-uploader ${validationStateClass}`"
    :label="label"
    :label-for="field"
    :description="description"
  >
    <transition-group name="image-list" tag="div" class="form-row">
      <b-col
        v-for="(image, idx) in value"
        :key="image.id"
        lg="4"
        md="6"
        class="mb-2 position-relative image-wrapper image-list-item"
      >
        <safe-image
          class="safe-image"
          :class="{
            'main-image': idx === 0,
          }"
          :image="image"
          :aspect-ratio="previewAspectRatio"
          :alt="alt || label"
        />
        <b-button-group class="image-actions">
          <icon-button
            v-b-tooltip="'Marquer comme image principale'"
            size="sm"
            :disabled="idx === 0"
            variant="ghost-primary"
            icon="pin-angle"
            @click="() => setMainImage(image)"
          >
          </icon-button>
          <icon-button
            v-if="!disabled"
            role="remove-item"
            size="sm"
            @click="() => removeImage(image)"
          >
          </icon-button>
        </b-button-group>
      </b-col>

      <b-col v-if="loading" key="loading" lg="4" md="6" class="mb-2 image-list-item skeleton">
        <b-skeleton height="100%" />
      </b-col>

      <b-col key="add-item" lg="4" md="6" class="mb-2 image-list-item">
        <b-form-file
          :id="field"
          ref="imageinput"
          :style="aspectRatioStyle"
          :value="newImage"
          :state="validationState"
          placeholder="Ajouter une image"
          :disabled="disabled"
          :name="field"
          :accept="accept.join(',')"
          browse-text="Sélectionner"
          drop-placeholder="Déposer l'image ici..."
          @change="handleChange"
        />
        <div v-if="error" class="invalid-feedback">
          {{ error }}
        </div>
      </b-col>
    </transition-group>
  </b-form-group>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import { uploadImage } from "@/requests/fileRequests";

export default {
  name: "FormsImagesUploader",
  components: { IconButton, SafeImage },
  props: {
    accept: {
      default: () => ["*.png", "*.jpg", "*.jpeg", "image/png", "image/jpg", "image/jpeg"],
      type: Array,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    field: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    placeholder: {
      default: "Téléverser...",
      type: String,
    },
    removeImageText: {
      default: "Retirer l'image",
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    // Array of images
    value: {
      type: Array,
      require: false,
      default: undefined,
    },
    previewAspectRatio: {
      type: String,
      required: false,
      default: undefined,
    },
    alt: {
      type: String,
      default: undefined,
    },
  },
  data: function () {
    return {
      loading: false,
      newImage: null,
      error: null,
    };
  },
  computed: {
    validationState() {
      return !this.error && ((this.required && !!this.value) || this.state);
    },
    validationStateClass() {
      switch (this.validationState) {
        case true:
          return "is-valid";
        case false:
          return "is-invalid";
        default:
          return "";
      }
    },
    aspectRatioStyle() {
      if (this.previewAspectRatio) {
        return {
          "aspect-ratio": this.previewAspectRatio,
        };
      }

      return {
        "min-height": "10rem",
      };
    },
  },
  methods: {
    handleChange(event) {
      switch (event.type) {
        case "drop":
          this.uploadImage(this.field, event.dataTransfer.files);
          break;
        default:
          this.uploadImage(this.field, event.target.files);
          break;
      }
    },
    removeImage(image) {
      const newImages = this.value.filter((i) => i.id !== image.id);

      this.$emit("input", this.reorderImages(newImages));
    },
    setMainImage(image) {
      const newImages = this.reorderImages([image, ...this.value.filter((i) => i.id !== image.id)]);

      this.$emit("input", newImages);
      // Hide tooltips: fixes the tooltip for the pin button staying visible after click.
      this.$root.$emit("bv::hide::tooltip");
    },
    reorderImages(images) {
      for (let i = 0; i < images.length; i++) {
        images[i].order = i;
      }
      return images;
    },
    async uploadImage(fieldName, fileList) {
      this.loading = true;
      const { file, error } = await uploadImage(fieldName, fileList, {
        order: this.value?.length ?? 0,
      });
      this.loading = false;
      if (file) {
        this.$emit("input", this.reorderImages([...this.value, file]));
      }
      this.error = error;
      this.$refs.imageinput.reset();
    },
  },
};
</script>

<style lang="scss">
.forms-images-uploader {
  width: 100%;
  min-height: 200px;

  .image-list-leave-to,
  .image-list-enter {
    opacity: 0;
  }

  .image-list-move,
  .image-list-enter-active {
    width: 100%;
    transition: all 0.5s ease;
  }

  .image-list-leave-active {
    position: absolute !important;
    transition-duration: 0s;
  }

  .custom-file {
    height: unset;
  }

  .safe-image {
    box-shadow: $medium-shadow;
    &.main-image {
      box-shadow:
        $medium-shadow,
        0 0 2px 2px $locomotion-green;
      transition-delay: 0.5s;
      transition-duration: 0.5s;
    }
  }

  .skeleton {
    opacity: 0.5;
  }

  .image-actions {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    overflow: hidden;
  }

  .preview {
    max-height: 10rem;
  }

  .custom-file-label {
    overflow: hidden;
    height: 100%;
    text-align: center;

    border: 1px dashed $light-grey;

    background-image: url("/cloud.svg");
    background-repeat: no-repeat;
    background-size: 100% calc(100% - 5rem);
    background-position: top calc(50% - 6px) center;

    &.dragging {
      background-image: url("/cloud-active.svg");
      border: 1px dashed $primary;
    }

    &::after {
      border-left: 0;
      border-radius: 0.25rem;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      top: auto;
      width: 50%;
      min-width: 200px;
      margin: 0 auto;
    }
  }

  + .invalid-feedback {
    margin-top: -20px;
  }
}
</style>
