<template>
  <div class="safe-image image-wrapper" :style="[aspectRatioStyle]">
    <img v-if="imageInitiallyVisible" :src="url" :alt="alt || image.original_filename" />

    <lazy-load v-else :offset="50" class="w-100 h-100" @load="loadImage">
      <!-- Transition from blurry to fully loaded image -->
      <!-- Specifying the duration manually here seems to work better than the default -->
      <transition name="cross-fade" appear :duration="500">
        <img
          v-if="image && image.sizes && image.sizes.blur && !showImage"
          class="blur"
          :src="image.sizes.blur"
          :style="[aspectRatioStyle]"
          :alt="alt || image.original_filename"
        />
      </transition>
      <transition
        name="cross-fade"
        appear
        :duration="500"
        @afterEnter="onInserted"
        @afterLeave="inserted = false"
      >
        <img v-if="showImage" :src="url" :alt="alt || image.original_filename" />
      </transition>
    </lazy-load>

    <b-progress
      v-if="!imageInitiallyVisible && showProgress && !showImage"
      variant="secondary"
      class="image-progress"
      :value="percentCompleted"
      height="2px"
    />

    <template v-if="!noZoom && showImage">
      <b-link v-b-modal="`image-${image.id}-${uniqueId}`">
        <b-icon icon="search" class="zoom" />
      </b-link>

      <b-modal
        :id="`image-${image.id}-${uniqueId}`"
        size="lg"
        hide-footer
        :title="alt || image.original_filename"
      >
        <safe-image
          :image="image"
          :preferred-sizes="['original']"
          no-zoom
          class="img-fit"
          :alt="alt || image.original_filename"
        />
      </b-modal>
    </template>
  </div>
</template>

<script>
import LazyLoad from "@/components/shared/Lazy.vue";
import Vue from "vue";

let uniqueId = 0;
export default Vue.extend({
  name: "SafeImage",
  components: { LazyLoad },
  props: {
    image: {
      type: Object,
      default: undefined,
    },
    aspectRatio: {
      type: String,
      default: undefined,
    },
    // Whether the container has a fixed height limit
    heightBounded: {
      type: Boolean,
      default: false,
    },
    preferredSizes: {
      type: Array,
      default: () => {
        return ["thumbnail", "original"];
      },
    },
    noZoom: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: undefined,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let imageInitiallyVisible = false;

    // Check if image is cached on component creation, and if so avoid
    // the fanciful loading,
    if (this.image && this.$store.state.imageCache.images[this.image.id]) {
      for (const size of this.preferredSizes) {
        if (this.$store.state.imageCache.images[this.image.id][size]?.url) {
          imageInitiallyVisible = true;
          break;
        }
      }
    }

    return {
      uniqueId: uniqueId++,
      imageInitiallyVisible,
      dimensionObserver: null,
      containerWidth: null,
      containerHeight: null,
      inserted: false,
      width: null,
      height: null,
    };
  },
  computed: {
    aspectRatioStyle() {
      if (!this.image) {
        return {};
      }

      if (this.aspectRatio) {
        return {
          aspectRatio: this.aspectRatio,
          objectFit: "cover",
        };
      }

      if (!this.inserted && this.width && this.height) {
        return {
          width: this.width,
          height: this.height,
        };
      }

      return {};
    },
    url() {
      return this.loadedImage?.url;
    },
    percentCompleted() {
      return this.loadedImage?.percentCompleted;
    },
    loadedImage() {
      if (!this.image) {
        return null;
      }

      let imageSizes = this.$store.state.imageCache.images[this.image.id];
      if (!imageSizes) {
        return null;
      }

      for (const size of this.preferredSizes) {
        if (imageSizes[size]?.url || imageSizes[size]?.percentCompleted) {
          return imageSizes[size];
        }
      }
      return null;
    },
    showImage() {
      return !!this.url;
    },
  },
  watch: {
    "image.id": function () {
      this.observeDimensions();
      this.$nextTick(this.loadImage);
    },
  },
  mounted() {
    if (this.image) {
      this.observeDimensions();
    }
    // If image is ready to be displayed from the beginning (already loaded),
    // then there will be no transition to set inserted to true, so we need to set it here.
    if (this.showImage) {
      this.inserted = true;
    }
  },
  beforeDestroy() {
    this.unobserveDimensions();
  },
  methods: {
    async loadImage() {
      if (!this.image) {
        return;
      }

      // Input image doesn't specify which sizes are available, so we try the prefered sizes as is.
      if (!this.image.sizes || !this.image.sizes.api) {
        const loaded = await this.$store.dispatch("imageCache/loadImage", {
          id: this.image.id,
          requestedSizes: this.preferredSizes,
        });

        if (!loaded) {
          this.$emit("failed");
        }
        return;
      }

      // Otherwise, only fetch sizes which should exist
      const requestedSizes = [];

      for (const preferredSize of this.preferredSizes) {
        if (this.image.sizes.api.includes(preferredSize)) {
          requestedSizes.push(preferredSize);
        }
      }

      // No overlap between preferred and available sizes: fallback to original
      if (requestedSizes.length === 0) {
        requestedSizes.push("original");
      }

      const loaded = await this.$store.dispatch("imageCache/loadImage", {
        id: this.image.id,
        requestedSizes,
      });

      if (!loaded) {
        this.$emit("failed");
      }
    },
    computeDimensions({ containerHeight, containerWidth }) {
      // For the transition to be nice, we need both dimensions to be set.
      // The container can limit either or both of the outer dimensions.
      // If both width and height of the container are known, we don't need to do anything,
      // but if one is missing, we need to set it manually during the transition.

      if (containerWidth) {
        this.height =
          this.heightBounded && containerHeight
            ? containerHeight + "px"
            : ((this.image.height / this.image.width) * containerWidth).toFixed(2) + "px";
        this.width = containerWidth + "px";

        return true;
      }

      if (containerHeight) {
        this.height = containerHeight + "px";
        this.width = ((this.image.width / this.image.height) * containerHeight).toFixed(2) + "px";

        return true;
      }
      return false;
    },
    observeDimensions() {
      if (!this.dimensionObserver && window.ResizeObserver) {
        this.dimensionObserver = new ResizeObserver(() => {
          const dimensionsSet = this.computeDimensions({
            containerWidth: this.$el?.clientWidth,
            containerHeight: this.$el?.clientHeight,
          });

          if (dimensionsSet) {
            this.unobserveDimensions();
          }
        });
        this.dimensionObserver.observe(this.$el);
      }
    },
    unobserveDimensions() {
      if (this.dimensionObserver) {
        this.dimensionObserver.unobserve(this.$el);
        this.dimensionObserver = null;
      }
    },
    onInserted() {
      this.inserted = true;
    },
  },
});
</script>

<style lang="scss">
.safe-image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .cross-fade-leave,
  .cross-fade-enter-to {
    opacity: 1;
  }

  .cross-fade-enter {
    opacity: 0;
  }

  .cross-fade-leave-to.blur {
    transform: scale(1);
    filter: blur(0px);
  }

  .cross-fade-leave-active,
  .cross-fade-enter-active {
    position: absolute;
  }

  .blur {
    filter: blur(5px);
    transform: scale(1.25);
    transition: 0.5s all;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    position: relative;
    left: 0;
    transition: 0.5s opacity;
  }
  .zoom {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: white;
    filter: drop-shadow(0 0 2px black);
    opacity: 0;
    transition: 0.3s opacity;
  }
  &:hover .zoom {
    opacity: 1;
  }
  .image-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
