<template>
  <div class="date-range-picker" @click.stop>
    <vue2-date-picker
      ref="datePicker"
      type="date"
      range
      :value="splitValue"
      range-separator=" au "
      :inline="inline"
      :input-class="formControlClass"
      @input="emitInput"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import vue2DatePicker from "vue2-datepicker";

export default {
  name: "FormsDateRangePicker",
  components: {
    vue2DatePicker,
  },
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
    filterFormat: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    splitValue() {
      if (this.value && this.filterFormat) {
        return this.value.split("@").map((d) => dayjs(d).toDate());
      }
      return this.value?.map((d) => (d ? dayjs(d).toDate() : d));
    },

    formControlClass() {
      if (!this.size) {
        return "form-control";
      }
      return "form-control form-control-" + this.size;
    },
  },
  methods: {
    emitInput(values) {
      if (!this.filterFormat) {
        this.$emit(
          "input",
          values.map((d) => (d ? d.format("YYYY-MM-DD") : null))
        );
        return;
      }

      if (values && values.length === 2) {
        let start = values[0] ? dayjs(values[0]).startOf("day").toISOString() : null;
        let end = values[1] ? dayjs(values[1]).endOf("day").toISOString() : null;

        if (!start && !end) {
          this.$emit("input", null);
          return;
        }

        this.$emit("input", `${start || ""}@${end || ""}`);
        return;
      }
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss">
.date-range-picker {
  display: flex;
  flex: 1;
}
</style>
