<template>
  <div class="admin-sidebar" @click="$emit('click')">
    <component :is="itemComponent" name="item" :to="'/admin/dashboard'">{{
      capitalize($t("titles.dashboard"))
    }}</component>

    <template v-if="isGlobalAdmin || hasMoreThanOneAdminCommunity">
      <component :is="itemComponent" name="item" to="/admin/communities">Communautés</component>
      <component :is="itemComponent" name="item" to="/admin/users">Membres</component>
    </template>
    <component
      :is="itemComponent"
      v-else-if="firstAdminCommunity"
      name="item"
      :to="`/admin/communities/${firstAdminCommunity.id}`"
      >{{ firstAdminCommunity.name }}</component
    >
    <component :is="itemComponent" name="item" to="/admin/invitations">Invitations</component>
    <component :is="itemComponent" name="item" to="/admin/loanables">Véhicules</component>
    <component :is="itemComponent" name="item" to="/admin/loans">Emprunts</component>
    <component :is="itemComponent" v-if="isGlobalAdmin" name="item" to="/admin/pricings"
      >Tarifications</component
    >
    <component :is="itemComponent" v-if="isGlobalAdmin" name="item" to="/admin/invoices"
      >Factures</component
    >
    <component :is="itemComponent" v-if="isGlobalAdmin" name="item" to="/admin/padlocks"
      >Cadenas</component
    >
    <component
      :is="sectionComponent"
      v-if="isGlobalAdmin"
      :class="sectionClass"
      name="section"
      @click.prevent.stop="() => (advancedVisible = !advancedVisible)"
    >
      <div class="admin-sidebar-section-title">
        <b-icon
          font-scale="0.75"
          icon="chevron-right"
          class="sidebar-section-icon"
          :class="{
            'sidebar-section-icon-open': advancedVisible,
          }"
        />
        Avancé
      </div>
    </component>
    <b-collapse
      v-if="isGlobalAdmin"
      id="admin_sidebar_advanced"
      class="admin-sidebar-section"
      :visible="advancedVisible"
      tag="ul"
    >
      <component :is="itemComponent" name="item" to="/admin/exports">Exports</component>
      <component :is="itemComponent" name="item" to="/admin/gbfs_datasets">Flux GBFS</component>
    </b-collapse>
  </div>
</template>

<script>
import { capitalize } from "@/helpers/filters";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export default {
  name: "AdminSidebar",
  props: {
    sectionClass: {
      type: String,
      default: "",
    },
    itemComponent: {
      type: String,
      default: undefined,
    },
    sectionComponent: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      advancedVisible: false,
    };
  },
  computed: {
    isGlobalAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
    hasMoreThanOneAdminCommunity() {
      let hasOneAdminCommunity = false;
      for (const community of this.$store.state.user.communities) {
        if (community.role === "admin") {
          if (hasOneAdminCommunity) {
            return true;
          } else {
            hasOneAdminCommunity = true;
          }
        }
      }
      return false;
    },
    firstAdminCommunity() {
      for (const community of this.$store.state.user.communities) {
        if (community.role === "admin") {
          return community;
        }
      }
      return null;
    },
  },
  methods: { capitalize },
};
</script>

<style lang="scss">
.admin-sidebar {
  .admin-sidebar-section-title {
    display: flex;
    align-items: center;
  }
  .sidebar-section-icon {
    transition-duration: 0.3s;
    margin-right: 0.5rem;

    &.sidebar-section-icon-open {
      transform: rotate(90deg);
    }
  }

  .nav-item a.nav-link {
    color: $black;

    &.disabled {
      color: $grey;
    }

    &.router-link-active,
    &:hover {
      color: $locomotion-green;
    }
  }

  .admin-sidebar-section {
    list-style: none;
    padding-left: 1.25rem;
  }
}
</style>
