<template>
  <b-container v-if="itemLoaded && routeDataLoaded" fluid>
    <b-row>
      <b-col>
        <h2 v-if="item.name">{{ item.name }}</h2>
        <h2 v-else>
          <em>{{ $tc("padlock", 1) | capitalize }}</em>
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <dl>
          <dt>
            {{ capitalize($t("fields.mac_address")) }}
          </dt>
          <dd>
            {{ item.mac_address }}
          </dd>
          <dt>
            {{ capitalize($t("fields.external_id")) }}
          </dt>
          <dd>
            {{ item.external_id }}
          </dd>
          <dt>
            {{ capitalize($t("fields.loanable_id")) }}
          </dt>
          <dd>
            <router-link v-if="item.loanable" :to="`/admin/loanables/${item.loanable.id}`">{{
              item.loanable.name
            }}</router-link>
          </dd>
        </dl>
      </b-col>
    </b-row>
  </b-container>
  <layout-loading v-else />
</template>

<script>
import locales from "@/locales";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import { capitalize } from "../../helpers/filters";

export default {
  name: "AdminPadlock",
  mixins: [DataRouteGuards, FormMixin],
  methods: { capitalize },
  i18n: {
    messages: {
      en: {
        ...locales.en.padlocks,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.padlocks,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
