<template>
  <b-card no-body class="loan-form loan-actions loan-actions-handover">
    <b-card-header
      v-b-toggle.loan-actions-handover
      header-tag="header"
      role="tab"
      class="loan-actions__header"
    >
      <h2>
        <svg-danger
          v-if="
            (action.status === 'in_process' && loanIsCanceled) ||
            action.status === 'canceled' ||
            hasActiveExtensions
          "
        />
        <svg-waiting v-else-if="action.status === 'in_process'" />
        <svg-check v-else-if="action.status === 'completed'" />

        Retour
      </h2>

      <span
        v-if="
          hasActiveExtensions ||
          (item.is_contested && action.status === 'in_process' && !loanIsCanceled)
        "
      >
        Bloqué
      </span>
      <span v-else>
        <!--
          Canceled loans: current step remains in-process.
          Canceled action means contestation. Give way to canceled-loan status.
        -->
        <span
          v-if="(action.status === 'in_process' || action.status === 'canceled') && loanIsCanceled"
        >
          Emprunt annulé &bull; {{ item.canceled_at | datetime }}
        </span>
        <span v-else-if="action.status == 'in_process'"> En attente </span>
        <span v-else-if="action.status === 'completed'">
          Complété &bull; {{ action.executed_at | datetime }}
        </span>
        <span v-else-if="action.status === 'canceled'">
          Contesté &bull; {{ action.executed_at | datetime }}
        </span>
      </span>
    </b-card-header>

    <b-collapse id="loan-actions-handover" role="tabpanel" accordion="loan-actions" :visible="open">
      <b-card-body>
        <div
          v-if="(action.status === 'in_process' || action.status === 'canceled') && loanIsCanceled"
        >
          <p>L'emprunt a été annulé. Cette étape ne peut pas être complétée.</p>
        </div>
        <div v-else-if="hasActiveExtensions">
          <p>
            Une demande d'extension est en cours. Elle doit être complétée (acceptée, refusée ou
            annulée) avant de poursuivre.
          </p>
        </div>
        <div class="loan-actions-handover__instructions">
          <validation-observer ref="observer" v-slot="{ passes }">
            <template v-if="!canChangeHandoverInfo">
              <b-row>
                <b-col lg="6">
                  <forms-validated-input
                    id="mileage_end"
                    v-model="action.mileage_end"
                    name="mileage_end"
                    type="text"
                    label="KM au compteur"
                    disabled
                  />
                </b-col>
                <b-col v-if="action.image" lg="4" class="loan-actions-handover__form__image">
                  <safe-image :image="action.image" />
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col lg="6">
                  <forms-validated-input
                    id="purchases_amount"
                    v-model="action.purchases_amount"
                    name="purchases_amount"
                    type="currency"
                    :min="0"
                    disabled
                    label="Montant des dépenses"
                  />
                </b-col>
                <b-col v-if="action.expense" lg="6">
                  <safe-image :image="action.expense" />
                </b-col>
              </b-row>
            </template>

            <b-form
              v-else
              :novalidate="true"
              class="loan-actions-handover__form"
              @keydown.enter.prevent
              @submit.stop.prevent="passes(completeHandover)"
            >
              <action-step
                title="Entrez le KM au compteur du tableau de bord, au retour."
                :number="1"
                :show-header="isParticipant"
              >
                <forms-validated-input
                  id="mileage_end"
                  v-model="action.mileage_end"
                  name="mileage_end"
                  type="text"
                  :rules="{ required: true, min_value: mileageBeginning }"
                  label="KM au compteur"
                  mask="#########"
                  :formatter="integer"
                />
              </action-step>

              <action-step
                title="Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord qui
                  se trouve dans l'auto."
                :number="2"
                :show-header="isParticipant"
              >
                <b-row class="loan-actions-handover__form__image">
                  <b-col lg="6">
                    <forms-image-uploader
                      v-model="action.image"
                      label="Photo du tableau de bord"
                      field="image"
                    />
                  </b-col>
                  <b-col lg="2" class="d-flex justify-content-center align-items-center">
                    <rounded-tag class="loan-actions-handover__instructions__alternative">
                      ou
                    </rounded-tag>
                  </b-col>

                  <b-col lg="4" class="d-flex justify-content-center align-items-center">
                    <img
                      class="loan-actions-handover__form__booklet"
                      src="/carnetdebord.png"
                      alt="carnet de bord"
                    />
                  </b-col>
                </b-row>
              </action-step>

              <action-step
                title="Entrer le montant des dépenses effectuées par l'emprunteur-se."
                :number="3"
                :show-header="isParticipant"
              >
                <b-row>
                  <b-col lg="6">
                    <forms-validated-input
                      id="purchases_amount"
                      v-model="action.purchases_amount"
                      name="purchases_amount"
                      type="currency"
                      :min="0"
                      :label="isParticipant ? 'Montant' : 'Montant des dépenses'"
                    />
                    <b-alert
                      variant="warning"
                      :show="action.purchases_amount && action.purchases_amount > maxExpenses"
                    >
                      <small>
                        Le montant des dépenses ne peut pas excéder le montant remis au propriétaire
                        ({{ maxExpenses | currency }}) pour cet emprunt. Discutez avec la personne
                        propriétaire de l'auto pour obtenir le remboursement complet.
                      </small>
                    </b-alert>
                  </b-col>

                  <b-col lg="6">
                    <forms-image-uploader
                      v-model="action.expense"
                      label="Photo de la facture"
                      field="expense"
                    />

                    <small> Cette photo doit afficher le détail des dépenses. </small>
                  </b-col>
                </b-row>
              </action-step>

              <!--
                Display rule if actions not completed and not owner and borrower at the
                same time or if message exists.
              -->
              <hr
                v-if="
                  (!action.executed_at && !loanIsCanceled && !(isBorrower && isCoownerOrOwner)) ||
                  action.comments_by_borrower ||
                  action.comments_by_owner
                "
              />

              <b-row>
                <b-col>
                  <!-- Allow inputing a message to the owner if user is not the owner. -->
                  <forms-validated-input
                    v-if="!action.executed_at && !loanIsCanceled && isBorrower && !isCoownerOrOwner"
                    id="comments_by_borrower"
                    v-model="action.comments_by_borrower"
                    name="comments_by_borrower"
                    type="textarea"
                    :rows="3"
                    :disabled="!!action.commented_by_borrower_at"
                    label="Laissez un message au propriétaire (facultatif)"
                    placeholder="Commentaire sur la réservation"
                  />
                  <user-comment
                    v-if="action.comments_by_borrower"
                    :text="action.comments_by_borrower"
                    :user="borrowerUser"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <!-- Allow inputing a message to the borrower if user is not the borrower. -->
                  <forms-validated-input
                    v-if="!action.executed_at && !loanIsCanceled && isCoownerOrOwner && !isBorrower"
                    id="comments_by_owner"
                    v-model="action.comments_by_owner"
                    name="comments_by_owner"
                    type="textarea"
                    :rows="3"
                    :disabled="!!action.commented_by_owner_at"
                    label="Laissez un message à l'emprunteur (facultatif)"
                    placeholder="Commentaire sur la réservation"
                  />

                  <user-comment
                    v-if="action.comments_by_owner"
                    :text="action.comments_by_owner"
                    :user="owner.user"
                  />
                </b-col>
              </b-row>

              <b-row
                v-if="canChangeHandoverInfo"
                class="loan-actions-handover__buttons text-center"
              >
                <b-col>
                  <icon-button
                    :loading="actionLoading"
                    type="submit"
                    size="sm"
                    variant="success"
                    :disabled="!isContested && startsInTheFuture"
                  >
                    <span v-if="isContested">Corriger</span>
                    <span v-else>Enregistrer</span>
                  </icon-button>
                  <div v-if="!isContested && startsInTheFuture">
                    <small class="text-muted">
                      Il sera possible de terminer l'emprunt après l'heure du début de la
                      réservation ({{ zonedTimes.departureAt | shortDate }} à
                      {{ zonedTimes.departureAt | time }}){{
                        zonedTimes.differsFromCurrentTimezone
                          ? " " + zonedTimes.timezoneAbbrev
                          : ""
                      }}.
                    </small>
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="!action.executed_at && !loanIsCanceled" class="loan-actions__alert">
                <b-col>
                  <b-alert variant="warning" show>
                    Les informations de l'emprunt peuvent être modifiées jusqu'à 48h après sa
                    conclusion. À partir de ce moment, le coût de l'emprunt sera validé avec les
                    détails ci-dessus.
                  </b-alert>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>

        <div v-if="!isContested">
          <div v-if="isContestable && !loanIsCanceled && canContestHandoverInfo">
            <hr />

            <validation-observer ref="observer" v-slot="{ passes }">
              <b-form
                :novalidate="true"
                class="loan-actions-handover__contestation"
                @submit.stop.prevent="passes(contestHandover)"
              >
                <b-row>
                  <b-col lg="6">
                    <p>Cette information est-elle incorrecte?</p>
                    <p>
                      Pour la modifier, vous pouvez procéder à une "contestation". Par cette
                      procédure, un membre de l'équipe LocoMotion sera appelé à arbitrer la
                      résolution du conflit entre l'emprunteur et le propriétaire.
                    </p>
                  </b-col>

                  <b-col lg="6">
                    <forms-validated-input
                      id="comments_on_contestation"
                      v-model="action.comments_on_contestation"
                      name="comments_on_contestation"
                      type="textarea"
                      :rules="{ required: true }"
                      :rows="3"
                      label="Commentaires sur la contestation"
                      placeholder="Indiquer quelles sont les informations à corriger"
                    />
                  </b-col>
                </b-row>

                <b-row class="loan-actions-handover__buttons text-center">
                  <b-col>
                    <icon-button
                      size="sm"
                      variant="outline-danger"
                      :loading="actionLoading"
                      type="submit"
                    >
                      Contester
                    </icon-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <div v-else-if="!loanIsCanceled">
          <hr />

          <p>Les données ont été contestées {{ action.comments_on_contestation ? `:` : `` }}</p>

          <b-alert v-if="action.comments_on_contestation" variant="warning" show>
            {{ action.comments_on_contestation }}
          </b-alert>

          <p>
            Un membre de l'équipe LocoMotion contactera les participant-e-s et ajustera les données.
          </p>

          <div v-if="canResolveContestedLoan" class="text-center">
            <icon-button
              type="submit"
              size="sm"
              variant="success"
              :loading="actionLoading"
              @click="completeHandover"
            >
              Résoudre la contestation
            </icon-button>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import FormsImageUploader from "@/components/Forms/ImageUploader.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import ActionStep from "@/components/Loan/Actions/Step.vue";
import IconButton from "@/components/shared/IconButton.vue";
import RoundedTag from "@/components/shared/RoundedTag.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import UserComment from "@/components/shared/UserComment.vue";
import { integer } from "@/helpers/filters";
import { getZonedTimes } from "@/helpers/loanTimezones";
import {
  canChangeHandoverInfo,
  canContestHandoverInfo,
  canResolveContestedLoan,
} from "@/helpers/permissions/loans";

import LoanActionsMixin from "@/mixins/LoanActionsMixin";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import { put } from "@/requests/server";

export default {
  name: "LoanActionsHandover",

  components: {
    IconButton,
    SafeImage,
    RoundedTag,
    UserComment,
    ActionStep,
    FormsImageUploader,
    FormsValidatedInput,
  },
  mixins: [LoanActionsMixin, LoanStepsSequence],
  data() {
    return {
      canResolveContestedLoan: canResolveContestedLoan(this.user, this.item),
      canContestHandoverInfo: canContestHandoverInfo(this.user, this.item),
    };
  },
  computed: {
    zonedTimes() {
      return getZonedTimes(this.item);
    },
    mileageBeginning() {
      return this.item.takeover.mileage_beginning;
    },
    startsInTheFuture() {
      return this.$second.isBefore(this.item.departure_at, "minute");
    },
    canChangeHandoverInfo() {
      return canChangeHandoverInfo(this.user, this.item);
    },
    maxExpenses() {
      if (!this.item.borrower_invoice) {
        return;
      }

      return this.item.borrower_invoice.items.reduce((acc, billItem) => {
        if (billItem.item_type === "loan.price") {
          // Price for borrower is negative
          return acc - billItem.total;
        }
        return acc;
      }, 0);
    },
  },
  watch: {
    action(newValue) {
      if (!newValue.purchases_amount) {
        this.action.purchases_amount = 0;
      }
    },
    "action.mileage_end": async function (mileageEnd) {
      await this.$store.dispatch("loans/test", {
        estimated_distance: mileageEnd - this.mileageBeginning,
      });
    },
    "action.purchases_amount": async function (purchasesAmount) {
      if (!purchasesAmount || !this.action.mileage_end) {
        return;
      }

      await this.$store.dispatch("loans/test", {
        estimated_distance: this.action.mileage_end - this.mileageBeginning,
      });
    },
  },
  mounted() {
    if (!this.action.purchases_amount) {
      this.action.purchases_amount = 0;
    }
  },
  methods: {
    integer,
    async completeHandover() {
      this.actionLoading = true;

      await put(`/loans/${this.item.id}/handover/complete`, this.action, {
        notifications: { action: "retour du véhicule" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("completed");
    },
    async contestHandover() {
      this.actionLoading = true;

      await put(`/loans/${this.item.id}/handover/contest`, this.action, {
        notifications: { action: "contestation des informations" },
        cleanupCallback: () => (this.actionLoading = false),
      });
      this.$emit("canceled");
    },
  },
};
</script>

<style lang="scss">
.loan-actions-handover {
  &__form {
    &__booklet {
      height: 15rem;
    }
  }

  &__instructions {
    &__alternative {
      padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    }
  }
}
</style>
