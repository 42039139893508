import bikes from "./bikes";
import cars from "./cars";
import trailers from "./trailers";
import owners from "./owners";

export default {
  en: {
    fields: {},
  },
  fr: {
    new: {
      bike: "Nouveau vélo",
      car: "Nouvelle auto",
      trailer: "Nouvelle remorque",
      null: "Nouveau véhicule",
    },
    bike_types: {
      cargo: "vélo-cargo",
      cargo_electric: "vélo-cargo électrique",
      electric: "vélo électrique",
      fixed_wheel: "roue fixe",
      regular: "régulier",
    },
    create_loanable: "créer un véhicule",
    selected_loanables:
      "aucun véhicule sélectionné | 1 véhicule sélectionné | {count} véhicules sélectionnés",
    descriptions: {
      comments: "Quoi savoir sur ce véhicule avant de faire l'emprunt? (vu par tout le monde)",
      images:
        "Les images de votre véhicule s'afficheront dans un rapport d'aspect de 16 par 10. Assurez-vous qu'il est bien visible dans les aperçus ici.",
      instructions:
        "Quoi savoir sur l'accès ou l'utilisation de ce véhicule? (vu par l'emprunteur seulement)",
      trusted_borrower_instructions:
        "Si présentes, ces instructions remplaceront les instructions habituelles pour les membres du réseau de confiance.",
      location_description:
        "Généralement, votre véhicule se trouve où? Cliquez sur la carte " +
        "pour définir sa position.",
      name:
        "Merci de nommer votre véhicule pour en informer votre voisinage. Le nom n'a pas " +
        "besoin d'être compliqué. Allez-y au plus simple... ou au plus drôle!",
      sharing_mode:
        "Sur demande : chaque emprunt devra être approuvé.\n" +
        "Libre partage : les demandes seront automatiquement acceptées.\n" +
        "Libre partage limité : les demandes d'emprunt seront automatiquement acceptées pour les membres du réseau de confiance.",
    },
    engines: cars.fr.engines,
    fields: {
      ...bikes.fr.fields,
      ...cars.fr.fields,
      ...trailers.fr.fields,
      comments: "information sur le véhicule",
      deleted_at: "supprimé",
      images: "photos du véhicule",
      instructions: "instructions",
      trusted_borrower_instructions: "instructions pour le réseau de confiance",
      location_description: "précisions sur l'emplacement",
      name: "nom",
      owner: owners.fr.fields,
      owner_id: "propriétaire",
      padlock_id: "cadenas",
      position: "position géographique",
      type: "type de véhicule",
      types: {
        bike: "vélo",
        car: "auto",
        null: "n'importe quel type",
        trailer: "remorque",
      },
      is_deleted: "afficher les véhicules archivés",
      sharing_mode: "Mode de partage",
    },
    types_with_article: {
      bike: "le vélo",
      car: "l'auto",
      trailer: "la remorque",
    },
    model: {
      singular: "véhicule",
    },
    model_name: "véhicule | véhicules",
    papers_locations: {
      in_the_car: "dans l'auto",
      to_request_with_car: "à récupérer avec la clé",
    },
    placeholders: {
      comments:
        "ex.: SVP ne pas fumer dans mon auto. Merci beaucoup!\n" +
        "J'ai un siège pour bébé et un support pour les vélos en arrière.",
      instructions:
        "ex.: Appelez-moi pour récupérer les clés.\n" +
        "J'apprécierais que le siège de bébé soit replacé, s'il y a lieu.\n" +
        "Le code du cadenas pour accéder à la cour est le 1234.",
      trusted_borrower_instructions: "ex.: Le code du cadenas est le 5432.",
      location_description: "ex.: Généralement dans la ruelle, textez-moi pour plus de précisions.",
      name: "ex.: la LocoMobile bleue",
      padlock_id: "",
    },
    sharing_modes: {
      on_demand: "sur demande",
      self_service: "libre partage",
      hybrid: "libre partage limité",
    },
    sidebar: {
      details: "détails",
    },
    sizes: {
      big: "grand",
      medium: "moyen",
      small: "petit",
      kid: "enfant",
    },
    transmission_modes: cars.fr.transmission_modes,
    loanable: "véhicule | véhicule | véhicules",
    resource_people: "Personnes responsables",
  },
};
