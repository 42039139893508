<template>
  <b-card no-body class="loan-form loan-actions loan-actions-new loan-actions-creation">
    <b-card-header
      v-b-toggle.loan-actions-new
      header-tag="header"
      role="tab"
      class="loan-actions__header"
    >
      <h2>
        <svg-check v-if="item.id" />
        <svg-waiting v-else />

        {{ item.is_self_service ? "Réservation" : "Demande d'emprunt" }}
      </h2>

      <span v-if="!item.created_at">En cours de création</span>
      <span v-else>Complété &bull; {{ item.created_at | datetime }}</span>
    </b-card-header>

    <b-collapse id="loan-actions-new" role="tabpanel" accordion="loan-actions" :visible="open">
      <b-card-body>
        <validation-observer ref="observer" v-slot="{ passes }">
          <b-form
            :novalidate="true"
            class="form loan-form__form"
            @keydown.enter="preventIfEventNotFromTextArea"
            @submit.stop.prevent="passes(submit)"
            @reset.stop.prevent="$emit('reset')"
          >
            <b-alert v-if="item.loanable.comments" show variant="success">
              <div class="alert-heading">
                <h4>Commentaires du propriétaire sur le véhicule</h4>
              </div>
              <div class="owner-comments-text">
                <p>{{ item.loanable.comments }}</p>
              </div>
            </b-alert>

            <b-row>
              <b-col lg="6">
                <!--
                  Disable past times only if loan is editable.
                -->
                <forms-validated-input
                  name="departure_at"
                  :disabled="disabled"
                  :label="$t('fields.departure_at') | capitalize"
                  :rules="form.departure_at.rules"
                  type="datetime"
                  :disabled-dates-fct="!disabled ? disabledBeforeToday : null"
                  :disabled-times-fct="!disabled ? disabledBeforeNow : null"
                  :placeholder="placeholderOrLabel('departure_at') | capitalize"
                  :value="item.departure_at"
                  :timezone="item.loanable.timezone"
                  :clearable="false"
                  :description="
                    zonedTimes.differsFromCurrentTimezone
                      ? `Heure dans le fuseau horaire du véhicule: ${zonedTimes.timezoneAbbrev}`
                      : null
                  "
                  @input="changeSearchDate"
                />
              </b-col>

              <b-col lg="6">
                <!--
                  Disable past times only if loan is editable.
                -->
                <forms-validated-input
                  v-model="returnAt"
                  name="return_at"
                  :disabled="disabled"
                  :label="$t('fields.return_at') | capitalize"
                  :rules="form.departure_at.rules"
                  type="datetime"
                  :clearable="false"
                  :disabled-dates-fct="!disabled ? disabledBeforeDepartureDay : null"
                  :disabled-times-fct="!disabled ? disabledBeforeDeparture : null"
                  :placeholder="placeholderOrLabel('return_at') | capitalize"
                  :timezone="item.loanable.timezone"
                  @input="changeSearchDuration"
                />
              </b-col>
            </b-row>

            <b-row v-if="invalidDuration">
              <b-col>
                <b-alert show variant="danger">
                  La durée de l'emprunt doit être supérieure ou égale à 15 minutes.
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" :md="item.alternative_to === 'other' ? 6 : 12">
                <forms-validated-input
                  v-model="item.alternative_to"
                  name="alternative_to"
                  :disabled="disabled"
                  :label="$t('fields.alternative_to') | capitalize"
                  :rules="{
                    required: true,
                  }"
                  type="select"
                  :options="form.alternative_to.options"
                  @input="
                    (value) => {
                      if (value !== 'other') {
                        item.alternative_to_other = null;
                      }
                    }
                  "
                />
              </b-col>
              <b-col v-if="item.alternative_to === 'other'">
                <forms-validated-input
                  v-model="item.alternative_to_other"
                  name="alternative_to_other"
                  :disabled="disabled"
                  :label="$t('fields.alternative_to_other') | capitalize"
                  :rules="{
                    required: true,
                  }"
                  type="text"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <forms-validated-input
                  v-model="item.estimated_distance"
                  name="estimated_distance"
                  :label="$t('fields.estimated_distance') | capitalize"
                  type="text"
                  :min="0"
                  :rules="{ min_value: 0, required: true }"
                  :disabled="disabled"
                  mask="#######"
                  :formatter="integer"
                />
              </b-col>
            </b-row>

            <b-row>
              <!-- No message for owner if the loanable is self service. -->
              <b-col v-if="!item.is_self_service">
                <forms-validated-input
                  v-model="item.message_for_owner"
                  name="message_for_owner"
                  :disabled="disabled"
                  :label="
                    (`${$t('fields.message_for_owner')} ` + `(${$t('optional')})`) | capitalize
                  "
                  :rules="form.message_for_owner.rules"
                  type="textarea"
                  :rows="3"
                  :placeholder="placeholderOrLabel('message_for_owner') | capitalize"
                />
              </b-col>
            </b-row>

            <b-alert v-if="canModifyLoanInfo && willNeedReapproval" show variant="warning">
              <h4 class="alert-heading">Modification des heures de l'emprunt</h4>
              Le-a propriétaire du véhicule devra réapprouver votre emprunt.
            </b-alert>

            <b-row v-if="!disabled" class="form__buttons">
              <b-col class="text-center">
                <icon-button
                  v-if="(!item.id || isTimeChanged) && !item.loanable.available"
                  variant="primary"
                  disabled
                  type="submit"
                >
                  Indisponible
                </icon-button>
                <icon-button
                  v-else-if="!item.is_self_service"
                  type="submit"
                  variant="success"
                  :disabled="
                    loading ||
                    invalidDuration ||
                    disabled ||
                    !changed ||
                    (!item.id && hasBlockingIncidents)
                  "
                >
                  {{ !item.id ? "Faire la demande d'emprunt" : "Modifier" }}
                </icon-button>
                <icon-button
                  v-else
                  type="submit"
                  variant="success"
                  :disabled="
                    loading || invalidDuration || !changed || (!item.id && hasBlockingIncidents)
                  "
                >
                  {{ !item.id ? "Réserver" : "Modifier" }}
                </icon-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import Check from "@/assets/svg/check.svg";
import Waiting from "@/assets/svg/waiting.svg";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { integer } from "@/helpers/filters";
import { getZonedTimes } from "@/helpers/loanTimezones";
import {
  isBorrower,
  isLoanAdmin,
  loanableHasIncidentBlockingLoan,
} from "@/helpers/permissions/loans";

import locales from "@/locales";

import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import LoanFormMixin from "@/mixins/LoanFormMixin";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import dayjs from "dayjs";

export default {
  name: "LoanForm",
  components: {
    IconButton,
    FormsValidatedInput,
    "svg-check": Check,
    "svg-waiting": Waiting,
  },
  mixins: [FormLabelsMixin, LoanFormMixin, LoanStepsSequence],
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return this.$store.state.loans.form;
    },
    returnAt: {
      get() {
        return dayjs(this.item.departure_at)
          .add(this.item.duration_in_minutes, "minute")
          .toISOString();
      },
      set(val) {
        this.item.duration_in_minutes = dayjs(val).diff(this.item.departure_at, "minute");
      },
    },
    zonedTimes() {
      return getZonedTimes(this.item);
    },
    canModifyLoanInfo() {
      return (
        isBorrower(this.user, this.item) || (!!this.item.id && isLoanAdmin(this.user, this.item))
      );
    },
    initialDepartureAt() {
      return this.$store.getters["loans/initialItem"].departure_at;
    },
    hasBlockingIncidents() {
      return loanableHasIncidentBlockingLoan(this.item.loanable, this.item);
    },
    disabled() {
      return (
        this.loanIsCanceled ||
        !this.canModifyLoanInfo ||
        (this.item.id && this.$second.isAfter(this.initialDepartureAt))
      );
    },
    willNeedReapproval() {
      return (
        this.isTimeChanged &&
        this.item.intention &&
        this.item.intention.status === "completed" &&
        !this.item.is_self_service
      );
    },
    isTimeChanged() {
      if (!this.item.id) {
        return false;
      }

      const initialItem = this.$store.getters["loans/initialItem"];

      return (
        this.item.duration_in_minutes !== initialItem.duration_in_minutes ||
        !dayjs(this.item.departure_at).isSame(initialItem.departure_at)
      );
    },
    changed() {
      if (!this.item.id) {
        return true;
      }

      // shallow clone
      const initialItem = {
        ...this.$store.getters["loans/initialItem"],
      };
      const currentItem = {
        ...this.item,
      };

      const fieldsToCompare = [
        "departure_at",
        "duration_in_minutes",
        "message_for_owner",
        "alternative_to",
        "alternative_to_other",
        "estimated_distance",
      ];

      for (const field of fieldsToCompare) {
        if (initialItem[field] !== currentItem[field]) {
          return true;
        }
      }
      return false;
    },
    invalidDuration() {
      return this.item.duration_in_minutes < 15;
    },
    loading() {
      return this.$store.state.loans.loading;
    },
    // This is used to send a request to update the availability and estimation of the loan
    loanParams() {
      return JSON.stringify({
        // Server and dayjs do not format ISO strings exactly the same way (3 vs 6 '0' before z), so
        // we need to reformat here to make sure the date is truly different before sending an
        // estimation request.
        departure_at: dayjs(this.item.departure_at).toISOString(),
        duration_in_minutes: this.item.duration_in_minutes,
        estimated_distance: this.item.estimated_distance,
        loanable_id: this.item.loanable.id,
      });
    },
  },
  watch: {
    loanParams(newValue, oldValue) {
      if (newValue !== oldValue && !this.invalidDuration) {
        this.estimateLoan();
      }
    },
  },
  beforeMount() {
    // Estimated the cost for new items
    if (!this.disabled) {
      this.estimateLoan();

      // Make sure date is in ISO format in the loanable timezone.
      // Loanable search sets a zone-less date-time.
      this.item.departure_at = dayjs
        .atTz(this.item.departure_at, this.item.loanable.timezone)
        .toISOString();
    }
  },
  methods: {
    integer,
    submit() {
      this.$emit("submit");
    },
    disabledBeforeToday(date) {
      return dayjs().isAfter(dayjs(date).tz(this.item.loanable.timezone, true), "day");
    },
    disabledBeforeNow(date) {
      return dayjs().isAfter(dayjs(date).tz(this.item.loanable.timezone, true), "minutes");
    },
    disabledBeforeDeparture(date) {
      return dayjs(date)
        .tz(this.item.loanable.timezone, true)
        .isSameOrBefore(this.item.departure_at, "minute");
    },
    disabledBeforeDepartureDay(date) {
      return dayjs(date)
        .tz(this.item.loanable.timezone, true)
        .isBefore(this.item.departure_at, "day");
    },
    preventIfEventNotFromTextArea(event) {
      if (event.target.type !== "textarea") {
        event.preventDefault();
      }
    },
    changeSearchDate(datetime) {
      if (this.invalidDuration) {
        // If duration is negative, we allow user to move each date independently
        const newDuration = dayjs(this.returnAt).diff(datetime, "minute");
        this.$store.commit("loans/patchItem", {
          departure_at: datetime,
          duration_in_minutes: newDuration,
        });
      } else {
        this.$store.commit("loans/patchItem", {
          departure_at: datetime,
        });
      }

      this.$store.commit(
        "loanable.search/lastSearchDate",
        dayjs.atTz(datetime, this.item.loanable.timezone).format("YYYY-MM-DD HH:mm")
      );
    },
    changeSearchDuration() {
      this.$store.commit("loanable.search/lastSearchDuration", this.item.duration_in_minutes);
    },
    async estimateLoan() {
      try {
        await this.$store.dispatch("loans/test");
      } catch (e) {
        if (e.request?.status) {
          this.$store.commit("addNotification", {
            content: "Ce véhicule n'est pas partagé dans votre communauté.",
            title: "Véhicule inaccessible",
            variant: "danger",
          });
          this.$router.push("/app");
        }
      }
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
.loan-form__estimations__loading {
  max-width: 100px;
  max-height: 30px;
}
.owner-comments-text {
  white-space: pre-wrap;
}
</style>
