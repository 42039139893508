<template>
  <div class="awareness-banner">
    <img v-if="image" class="awareness-banner__image" :src="image" />

    <div class="awareness-banner__main-column">
      <h2>{{ title }}</h2>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AwarenessBanner",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.awareness-banner {
  display: grid;
  grid-template-columns: 16rem 1fr;
  border-radius: 0.5rem;
  align-items: start;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  &__image {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    max-width: 14rem;
    aspect-ratio: 1 / 1;
    margin: 1rem;

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
