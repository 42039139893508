import { extractErrors } from "@/helpers";
import { capitalize } from "@/helpers/filters";
import i18n from "@/i18n";
import fr from "@/locales/fr";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import dayjs from "dayjs";

/** @type {Object.<string, CancelTokenSource>}  */
const cancelTokens = {};
let refreshingTokenPromise = null;

const errorMessages = {
  400: {
    contentFunc: ({ response }) => (response?.data ? extractErrors(response.data).join(", ") : ""),
    title: "erreur dans la requête",
    variant: "danger",
  },
  401: {
    content: "Votre connexion est expirée, veuillez vous reconnecter.",
    title: "erreur d'authentification",
    variant: "danger",
  },
  403: {
    contentFunc: ({ ressourceName }) =>
      `Vous n'avez pas les permissions nécessaires pour faire cette action sur ${ressourceName}.`,
    title: "accès interdit",
    variant: "danger",
  },
  404: {
    contentFunc: ({ ressourceName }) => `Impossible de trouver ${ressourceName}.`,
    title: "ressource introuvable",
    variant: "danger",
  },
  422: {
    contentFunc: ({ response }) => extractErrors(response.data).join(", "),
    title: "erreur de validation",
    variant: "danger",
  },
  429: {
    contentFunc: ({ ressourceName }) =>
      `Nous recevons trop de demandes pour ${ressourceName}. Veuillez réessayer plus tard.`,
    title: "trop de requêtes",
    variant: "danger",
  },
  500: {
    content: "Une erreur système s'est produite.",
    title: "erreur",
    variant: "danger",
  },
};

function formatErrorTitle(title, notificationLabel = null) {
  if (!notificationLabel) {
    return title;
  }

  return `${capitalize(notificationLabel)}: ${title}`;
}

function guessRessourceName(url) {
  for (const slugKey in fr.errorRessourceNames) {
    const slugIndex = url.indexOf(slugKey);
    if (slugIndex >= 0) {
      const single = url.slice(slugIndex + 1 + slugKey.length).match(/\d+/);

      return i18n.tc(`errorRessourceNames.${slugKey}`, single ? 1 : 2);
    }
  }

  return "cette ressource";
}

function checkTokenSyncedInLocalStorage() {
  if (!localStorage?.locomotion) {
    return;
  }
  // Token may have been refreshed in a different window, updating the localstorage token.
  try {
    const localStorageState = JSON.parse(localStorage.locomotion);
    if (localStorageState.token !== store.state.token) {
      store.commit("setTokens", {
        token: localStorageState.token,
        refreshToken: localStorageState.refreshToken,
        tokenExpiresOn: localStorageState.tokenExpiresOn,
      });
    }
  } catch (e) {
    // Do nothing if locomotion localstorage is malformed
  }
}

async function maybeRefreshToken() {
  if (!store.state.token || !store.state.tokenExpiresOn || !store.state.refreshToken) {
    return;
  }

  // Do not have more than one request refreshing the token at a time
  if (refreshingTokenPromise) {
    await refreshingTokenPromise;
    return;
  }

  // Token expires in less than a day, we should try to refresh it.
  if (dayjs().add(1, "day").isAfter(store.state.tokenExpiresOn)) {
    try {
      refreshingTokenPromise = axios.post("/auth/token/refresh", {
        refresh_token: store.state.refreshToken,
      });
      const { data } = await refreshingTokenPromise;

      store.commit("setTokens", {
        token: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
      });

      refreshingTokenPromise = null;
    } catch (e) {
      await logout();
      throw e;
    }
  }
}

async function logout() {
  if (router.currentRoute.name !== "login") {
    await router.push(`/login?logout=1&r=${router.currentRoute.fullPath}`);
  }
  // Token is not valid.
  store.commit("setTokens", {});
  await store.dispatch("logout");
}

/**
 * Sends a XHR using the provided requestConfig.
 *
 * @param {AxiosRequestConfig} requestConfig
 * @param {?RequestOptions} requestOptions
 * @param {?NotificationOptions} notifications
 * @param {?function} cleanupCallback - function to run after the request completes or fails, but not
 *  if cancelled because of requestOptions.cancelId.
 *
 * @return {any} Response from the server
 * @throws {Error} - Axios error if request fails.
 */
async function send(
  requestConfig,
  requestOptions = {},
  notifications = {},
  cleanupCallback = null
) {
  const { cancelId } = requestOptions;

  if (cancelId) {
    if (cancelTokens[cancelId]) {
      cancelTokens[cancelId].cancel();
    }
    cancelTokens[cancelId] = axios.CancelToken.source();
    requestConfig.cancelToken = cancelTokens[cancelId].token;
  }

  checkTokenSyncedInLocalStorage();
  await maybeRefreshToken();

  try {
    const response = await axios(requestConfig);

    if (cancelId) {
      delete cancelTokens[cancelId];
    }

    if (cleanupCallback && typeof cleanupCallback === "function") {
      cleanupCallback();
    }

    if (notifications.action && notifications.onSuccess) {
      store.commit("addNotification", {
        content: "",
        title: capitalize(
          typeof notifications.onSuccess === "string"
            ? notifications.onSuccess
            : `${notifications.action} réussi(e)!`
        ),
        variant: "success",
      });
    }

    return response;
  } catch (e) {
    if (axios.isCancel(e)) {
      // Request was canceled, because a new one was sent. No need to handle it, but need
      // to interrupt callers waiting on response.
      throw e;
    }

    if (cleanupCallback && typeof cleanupCallback === "function") {
      cleanupCallback();
    }

    if (cancelId) {
      delete cancelTokens[cancelId];
    }

    const expectedCodes = new Set(requestOptions.expects || []);

    const { request, response } = e;
    if (request?.status && expectedCodes.has(request.status)) {
      // do not handle, but throw the exception so the caller can handle it
      throw e;
    }

    // If auth error, logout and redirect to login
    if (request?.status === 401) {
      await logout();
    }

    if (!notifications.action) {
      throw e;
    }

    const ressourceName = notifications.ressourceName ?? guessRessourceName(requestConfig.url);

    if (request?.status && errorMessages[request.status]) {
      const errorMessage = errorMessages[request.status];
      store.commit(
        "addNotification",
        {
          content: errorMessage.contentFunc
            ? errorMessage.contentFunc({ request, response, ressourceName })
            : errorMessage.content,
          title: formatErrorTitle(errorMessage.title, notifications.action),
          variant: errorMessage.variant,
        },
        { root: true }
      );
    } else {
      store.commit(
        "addNotification",
        {
          content: "Une erreur inconnue s'est produite.",
          title: formatErrorTitle("Erreur", notifications.action),
          variant: "danger",
        },
        { root: true }
      );
    }

    throw e;
  }
}

/**
 * @param {string} url
 * @param data
 * @param {AxiosRequestConfig} axiosRequestConfig
 * @param {?RequestOptions} requestOptions
 * @param {?NotificationOptions} notifications
 * @param {?function} cleanupCallback
 *
 * @see {send}
 */
export async function put(
  url,
  data = null,
  { axiosRequestConfig = {}, requestOptions = {}, notifications = {}, cleanupCallback = null } = {}
) {
  return send(
    {
      ...axiosRequestConfig,
      method: "put",
      url,
      data: data,
    },
    requestOptions,
    notifications,
    cleanupCallback
  );
}

/**
 * @param {string} url
 * @param data
 * @param {AxiosRequestConfig} axiosRequestConfig
 * @param {?NotificationOptions} notifications
 * @param {?RequestOptions} requestOptions
 * @param {?function} cleanupCallback
 *
 * @see {send}
 */
export async function post(
  url,
  data = null,
  { axiosRequestConfig = {}, requestOptions = {}, notifications = {}, cleanupCallback = null } = {}
) {
  return send(
    {
      ...axiosRequestConfig,
      method: "post",
      url,
      data: data,
    },
    requestOptions,
    notifications,
    cleanupCallback
  );
}

/**
 * @param {string} url
 * @param {AxiosRequestConfig} axiosRequestConfig
 * @param {?NotificationOptions} notifications
 * @param {?RequestOptions} requestOptions
 * @param {?function} cleanupCallback
 *
 * @see {send}
 */
export async function get(
  url,
  { axiosRequestConfig = {}, requestOptions = {}, notifications = {}, cleanupCallback = null } = {}
) {
  return send(
    {
      ...axiosRequestConfig,
      method: "get",
      url,
    },
    requestOptions,
    notifications,
    cleanupCallback
  );
}

/**
 * @param {string} url
 * @param {AxiosRequestConfig} axiosRequestConfig
 * @param {?RequestOptions} requestOptions
 * @param {?NotificationOptions} notifications
 * @param {?function} cleanupCallback
 *
 * @see {send}
 */
export async function del(
  url,
  { axiosRequestConfig = {}, requestOptions = {}, notifications = {}, cleanupCallback = null } = {}
) {
  return send(
    {
      ...axiosRequestConfig,
      method: "delete",
      url,
    },
    requestOptions,
    notifications,
    cleanupCallback
  );
}

export async function options(url) {
  return send({
    method: "options",
    url,
  });
}
