import { isAdminOfSomeCommunity } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { mustBeAuthenticated } from "@/mixins/Authenticated";
import store from "@/store";
import AdminPage from "@/views/Admin.vue";
import AdminCommunities from "@/views/admin/Communities.vue";
import AdminCommunity from "@/views/admin/Community.vue";
import AdminDashboard from "@/views/admin/Dashboard.vue";
import AdminExports from "@/views/admin/Exports.vue";
import GbfsDataset from "@/views/admin/GbfsDataset.vue";
import GbfsDatasets from "@/views/admin/GbfsDatasets.vue";
import AdminInvoice from "@/views/admin/Invoice.vue";
import AdminInvoices from "@/views/admin/Invoices.vue";
import AdminLoan from "@/views/admin/Loan.vue";
import AdminLoanable from "@/views/admin/Loanable.vue";
import AdminLoanables from "@/views/admin/Loanables.vue";
import AdminLoans from "@/views/admin/Loans.vue";
import AdminPadlock from "@/views/admin/Padlock.vue";
import AdminPadlocks from "@/views/admin/Padlocks.vue";
import AdminPricings from "@/views/admin/Pricings.vue";
import AdminUser from "@/views/admin/User.vue";
import AdminUsers from "@/views/admin/Users.vue";
import CommunityInvitation from "../views/admin/Invitation.vue";
import AdminInvitations from "../views/admin/Invitations.vue";

function mustBeGlobalAdmin(to, from, next) {
  if (isGlobalAdmin(store.state.user)) {
    return next();
  }
  next("/admin/dashboard");
}

function mustBeAdminOfSomeCommunity(to, from, next) {
  if (!store.state.user) {
    return next("/login");
  }

  if (isGlobalAdmin(store.state.user) || isAdminOfSomeCommunity(store.state.user)) {
    return next();
  }
  next("/app");
}

// Executes a stack of guards, stopping if any return a non undefined value
function checkGuardsStack(guards, to, from, next) {
  const nextGuard = guards.shift();

  // No more guards
  if (nextGuard === undefined) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    // Previous guard did no redirect or cancel, we check following guards
    if (nextArg === undefined) {
      checkGuardsStack(guards, to, from, next);
      return;
    }

    next(nextArg);
  });
}
function guards(guards) {
  return (to, from, next) => {
    return checkGuardsStack(guards, to, from, next);
  };
}

export default [
  {
    path: "/admin",
    component: AdminPage,
    beforeEnter: guards([
      mustBeAuthenticated,
      mustBeAdminOfSomeCommunity,
      (to, from, next) => {
        if (to.path === "/admin") {
          next("admin/dashboard");
          return;
        }
        next();
      },
    ]),
    meta: {
      auth: true,
      title: "titles.admin",
    },
    children: [
      {
        path: "dashboard",
        component: AdminDashboard,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.dashboard",
        },
      },
      {
        path: "communities",
        component: AdminCommunities,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.communities",
        },
      },
      {
        path: "communities/:id",
        component: AdminCommunity,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "communities",
          title: "titles.community",
          params: {
            fields: ["*", "allowed_loanable_types.*"].join(","),
            for: "admin",
          },
          data: {
            loanableTypes: {
              loadTypes: {},
            },
          },
        },
      },
      {
        path: "loanables",
        component: AdminLoanables,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.loanables",
        },
      },
      {
        path: "loanables/:id",
        component: AdminLoanable,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "loanables",
          params: {
            fields:
              "*,active_incidents,owner.user.full_name,owner.user.name,owner.user.last_name,owner.user.communities.name,owner.user.available_loanable_types," +
              "owner.user.communities.id,owner.user.communities.area," +
              "padlock.name,details.*,details.report.*,images.*," +
              "coowners.id,coowners.title,coowners.show_as_contact,coowners.pays_loan_price,coowners.pays_loan_insurance,coowners.user.full_name," +
              "coowners.user.id,coowners.user.avatar.*,coowners.user.phone",
            with_deleted: true,
          },
          title: "titles.loanable",
        },
      },
      {
        path: "users",
        component: AdminUsers,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.users",
        },
      },
      {
        path: "users/:id",
        component: AdminUser,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "users",
          params: {
            fields:
              "*,owner.*,borrower.*,borrower.gaa.*,borrower.insurance.*,borrower.saaq.*,avatar.*",
          },
          title: "titles.user",
        },
      },
      {
        path: "invoices",
        component: AdminInvoices,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.invoices",
        },
      },
      {
        path: "invoices/:id",
        component: AdminInvoice,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        props: true,
        meta: {
          auth: true,
          slug: "invoices",
          params: {
            fields: "*,bill_items.*,user.*",
          },
          title: "titles.invoice",
          data: {
            users: {
              retrieveOne: {
                conditional({ route }) {
                  return !!route && !!route.query && !!route.query.user_id;
                },
                params: {
                  fields:
                    "full_name,address,postal_code,avatar.id,avatar.sizes,avatar.width,avatar.height",
                },
                id({ route: { query } }) {
                  return query.user_id;
                },
              },
            },
          },
        },
      },
      {
        path: "loans",
        component: AdminLoans,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.loans",
        },
      },
      {
        path: "loans/:id",
        component: AdminLoan,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "loans",
          title: "titles.loan",
        },
      },
      {
        path: "pricings",
        component: AdminPricings,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.pricings",
        },
      },
      {
        path: "padlocks",
        component: AdminPadlocks,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.padlocks",
        },
      },
      {
        path: "padlocks/:id",
        component: AdminPadlock,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        props: true,
        meta: {
          auth: true,
          slug: "padlocks",
          params: {
            fields: "*,loanable.name",
          },
          title: "titles.loan",
        },
      },
      {
        path: "exports",
        component: AdminExports,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.exports",
        },
      },
      {
        path: "invitations",
        component: AdminInvitations,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.invitations",
        },
      },
      {
        path: "invitations/new",
        component: CommunityInvitation,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.invitations",
        },
      },
      {
        path: "gbfs_datasets",
        component: GbfsDatasets,
        meta: {
          auth: true,
          title: "titles.gbfs",
        },
      },
      {
        path: "gbfs_datasets/:name",
        component: GbfsDataset,
        props: true,
        meta: {
          auth: true,
          title: "titles.gbfs",
        },
      },
    ],
  },
];
