<template>
  <layout-loading v-if="!pageLoaded" />
  <div v-else class="admin-loan-page">
    <vue-headful :title="fullTitle" />

    <loan-header :user="user" :loan="item" />

    <loan-action-buttons
      class="mb-3"
      :item="item"
      :cancel-loan-function="cancelLoan"
      :resume-loan-function="resumeLoan"
      @extension="addExtension"
      @incident="addIncident('accident')"
    />

    <b-row>
      <b-col lg="8">
        <div class="position-relative">
          <loan-incidents
            v-if="meaningfulIncidents && meaningfulIncidents.length > 0"
            :loan="item"
            :incidents="meaningfulIncidents"
          />
          <loan-actions
            :item="item"
            :user="user"
            :class="{ loading }"
            @load="loadItem"
            @submit="submitAndReload"
          />
          <layout-loading v-if="loading" class="actions-loading-spinner"></layout-loading>
        </div>
      </b-col>
      <b-col id="loan_details" lg="4" class="loan__sidebar">
        <loan-details-box vertical :loan="item" :loanable="item.loanable" :loan-loading="loading" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoanIncidents from "@/components/Incident/LoanIncidents.vue";
import LoanActionButtons from "@/components/Loan/ActionButtons.vue";
import LoanActions from "@/components/Loan/Actions.vue";
import LoanDetailsBox from "@/components/Loan/DetailsBox.vue";
import LoanHeader from "@/components/Loan/LoanHeader.vue";

import { capitalize } from "@/helpers/filters";

import locales from "@/locales";

import Authenticated from "@/mixins/Authenticated";
import FormMixin from "@/mixins/FormMixin";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "AdminLoan",
  components: {
    LoanIncidents,
    LoanActions,
    LoanActionButtons,
    LoanHeader,
    LoanDetailsBox,
  },
  mixins: [Authenticated, FormMixin, LoanStepsSequence, UserMixin],
  computed: {
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("model_name", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.item.name || capitalize(this.$i18n.tc("model_name", 1));
    },
    pageLoaded() {
      // this.id is the route id
      return this.item && this.item.id == this.id && this.form;
    },
  },
  methods: {
    async resumeLoan() {
      await this.$store.dispatch("loans/resume", {
        id: this.item.id,
        params: this.params,
      });
    },
    async submitAndReload() {
      await this.submit();
      // We assume that if we save correctly, that means the loan is in an availability period.
      // This is to avoid doing another /loan/test request.
      this.$store.commit("loans/mergeItem", { loanable: { available: true } });
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
        titles: locales.en.titles,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
        titles: locales.fr.titles,
      },
    },
  },
};
</script>

<style lang="scss">
.admin-loan-page {
  .actions-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading {
    opacity: 0.5;
  }
}
</style>
