import { render, staticRenderFns } from "./BankDetailsForm.vue?vue&type=template&id=ad48f910&scoped=true"
import script from "./BankDetailsForm.vue?vue&type=script&lang=js"
export * from "./BankDetailsForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad48f910",
  null
  
)

export default component.exports