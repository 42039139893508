<script>
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import DonationSlider from "@/components/shared/DonationSlider.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ProvisionModal from "@/components/User/ProvisionModal.vue";
import { debounce } from "@/helpers/debounce";
import { normalizeCurrency } from "@/helpers/filters";
import { summarizeBorrowerInvoice } from "@/helpers/invoices";
import { isBorrower } from "@/helpers/permissions/loans";
import Vue from "vue";

export default Vue.extend({
  name: "LoanDonations",
  components: { IconButton, DonationSlider, ProvisionModal, TinyInvoice },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    onpaid: {
      type: Function,
      required: true,
    },
    paymentLabel: {
      type: String,
      required: true,
    },
    paymentWarning: {
      type: String,
      default: undefined,
    },
    reloadUserOnPaid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platformTip: normalizeCurrency(this.loan.platform_tip),
      lastEstimatedPlatformTip: normalizeCurrency(this.loan.platform_tip),
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    actualPrice() {
      return -(this.loan.borrower_invoice?.user_balance_change ?? 0);
    },
    hasOnlyDonationInvoiceItem() {
      let hasDonationItem = false;
      for (const item of this.loan.borrower_invoice?.items ?? []) {
        if (item.item_type !== "donation.loan" && item.total !== 0) {
          return false;
        } else {
          hasDonationItem = true;
        }
      }

      return hasDonationItem;
    },
    hasNonContributionInvoiceItems() {
      for (const item of this.loan.borrower_invoice?.items ?? []) {
        if (item.item_type !== "loan.contribution" && item.total !== 0) {
          return true;
        }
      }

      return false;
    },
    borrowerInvoice() {
      return summarizeBorrowerInvoice(this.loan.borrower_invoice, {
        ownerName: this.loan.loanable.owner.user.name,
        communityName: this.loan.community.name,
        groupCompensations: true,
        contributionsDetails: true,
      });
    },
    evaluatingPrice() {
      return this.$store.state.loans.evaluatingPrice;
    },
    isInvoiceUpdating() {
      return (
        this.evaluatingPrice ||
        this.lastEstimatedPlatformTip !== this.platformTip ||
        this.$refs.donationSlider?.isChanging
      );
    },
  },
  created() {
    this.debouncedEstimatePrice = debounce(this.estimatePrice, 150);
    if (!this.loan.platform_tip) {
      this.tipAmountChanged(this.loan.desired_contribution);
    }
  },
  methods: {
    tipAmountChanged(newAmount) {
      this.platformTip = normalizeCurrency(newAmount ?? 0);
      this.$emit("input", this.platformTip);
      this.debouncedEstimatePrice();
    },
    async estimatePrice() {
      await this.$store.dispatch("loans/test", { platform_tip: this.platformTip });
      this.lastEstimatedPlatformTip = this.platformTip;
    },
    async handlePaid() {
      this.loading = true;
      try {
        await this.onpaid();
      } finally {
        this.loading = false;
      }
    },
    async handlePaymentButtonClick() {
      // await tick, since currency input only changes value on focus lost.
      await this.$nextTick();
      if (this.evaluatingPrice) {
        return;
      }
      if (this.actualPrice > this.user.balance) {
        this.$refs["provision-modal"].show();
      } else {
        await this.onpaid();
      }
    },
  },
});
</script>

<template>
  <div>
    <div
      v-if="isBorrower && loan.applicable_amount_types.contributions !== 'not_applicable'"
      class="mb-3"
    >
      <b-row>
        <b-col>
          <donation-slider
            ref="donationSlider"
            title="Par et pour la communauté"
            image="/Foire_des_Possibles2023.jpg"
            :disabled="loading"
            :amount="platformTip"
            :target="loan.desired_contribution"
            :subscription-available="loan.subscription_available"
            :contribution-required="loan.applicable_amount_types.contributions === 'required'"
            :pricing-loanable-type="loan.loanable.pricing_loanable_type"
            :community-id="loan.community.id"
            @amountChanged="tipAmountChanged"
          >
            <p>
              Le projet vit dans votre communauté grâce à des bénévoles qui donnent de leur temps.
            </p>
            <p class="mb-1">Contribuez vous aussi à ce que tout roule!</p>
          </donation-slider>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="borrowerInvoice.items.length > 0 && !hasOnlyDonationInvoiceItem"
      class="trip-cost-details"
    >
      <tiny-invoice
        :invoice="borrowerInvoice"
        :loading="isInvoiceUpdating"
        :styled="hasNonContributionInvoiceItems"
      >
      </tiny-invoice>
    </div>

    <provision-modal
      ref="provision-modal"
      :price="actualPrice"
      :loading="loading"
      :action-name="paymentLabel"
      :reload-user="reloadUserOnPaid"
      minimum-default
      @complete="
        () => {
          $bvModal.hide('payment-provision-modal');
          handlePaid();
        }
      "
    />

    <div class="text-center">
      <slot name="complete-button">
        <icon-button
          variant="primary"
          block
          :disabled="disabled || isInvoiceUpdating"
          :loading="loading"
          @click="handlePaymentButtonClick"
        >
          {{
            user.balance >= actualPrice
              ? paymentLabel
              : ("Ajouter au solde et " + paymentLabel) | capitalize
          }}
        </icon-button>
      </slot>
    </div>
    <b-alert v-if="paymentWarning" class="mt-2 mb-0" variant="warning" show>
      {{ paymentWarning }}
    </b-alert>
  </div>
</template>

<style scoped lang="scss"></style>
