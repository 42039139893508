var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-none"},[_c('div',{ref:"marker",staticClass:"loanable-map-marker",class:{
      ghost: _vm.ghost,
      outline: _vm.outline,
      car: _vm.loanable.type === 'car',
      trailer: _vm.loanable.type === 'trailer',
      bike: _vm.loanable.type === 'bike',
    }},[_c('pin',{ref:"pin",staticClass:"pin",attrs:{"id":`pin-${_vm.loanable.id}`}}),_c(_vm.glyph,{ref:"glyph",tag:"component",staticClass:"glyph"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }