<script>
import IconButton from "@/components/shared/IconButton.vue";
import UserAvatar from "@/components/User/Avatar.vue";
import { datetime } from "@/helpers/filters";
import Vue from "vue";

export default Vue.extend({
  name: "UserComment",
  components: { IconButton, UserAvatar },
  props: {
    text: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    updatedDate: {
      type: String,
      default: null,
    },
    destroy: {
      type: Function,
      required: false,
      default: null,
    },
    update: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isEditing: false,
      editingText: this.text,
    };
  },
  computed: {
    mine() {
      return this.user && this.user.id === this.$store.state.user.id;
    },
  },
  methods: {
    datetime,
    async updateText() {
      await this.update(this.editingText);
      this.isEditing = false;
    },
  },
});
</script>

<template>
  <div class="comment" :class="{ 'with-user': !!user, mine: mine }">
    <user-avatar v-if="user" :user="user" />
    <div v-if="user" class="bubble">
      <div class="bubble-fg"></div>
    </div>
    <div class="content">
      <div v-if="isEditing" class="input-group">
        <b-form-textarea v-model="editingText" size="sm" rows="3" />
        <icon-button
          class="input-group-append"
          :disabled="!editingText"
          role="send"
          :onclick="updateText"
        ></icon-button>
      </div>
      <blockquote v-else class="text">{{ text }}</blockquote>
      <div v-if="user || !!update || !!destroy" class="signature">
        <div class="signature-content">
          <span v-if="user"> {{ user.full_name }}</span
          ><span v-if="date && user" class="ml-1 mr-1">&bull;</span
          ><span v-if="date">{{ date | datetime }}</span>
          <span
            v-if="updatedDate && date !== updatedDate"
            v-b-tooltip="`Texte modifié le ${datetime(updatedDate)}`"
            >*</span
          >
        </div>
        <div v-if="!!update || !!destroy" class="actions">
          <icon-button
            v-if="isEditing"
            role="reset"
            variant="link"
            size="inline"
            @click="
              isEditing = false;
              editingText = text;
            "
          >
            Annuler</icon-button
          >
          <icon-button
            v-else-if="!!update"
            role="edit"
            variant="link"
            size="inline"
            @click="isEditing = true"
            >Modifier</icon-button
          >
          <icon-button v-if="!!destroy" role="delete" variant="link" size="inline" @click="destroy"
            >Supprimer</icon-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.comment {
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 1rem;
  --comment-color: rgba(87, 97, 148, 0.1);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--comment-color);
    border-radius: 1rem;
    padding: 0.75rem;
    flex: 1;
    text-align: start;
  }

  &.with-user {
    .content {
      border-top-left-radius: 0;
    }
    gap: 0;

    .bubble {
      width: 1rem;
      height: 1rem;
      margin-left: -0.5rem;
      background: var(--comment-color);
      .bubble-fg {
        background: white;
        width: 100%;
        height: 100%;
        border-top-right-radius: 100%;
      }
    }
  }

  &.mine {
    --comment-color: #e5f8f6;

    &.with-user {
      flex-direction: row-reverse;

      .content {
        border-top-left-radius: 1rem;
        border-top-right-radius: 0;
      }

      .bubble {
        margin-left: 0;
        margin-right: -0.5rem;

        .bubble-fg {
          border-top-left-radius: 100%;
          border-top-right-radius: 0;
        }
      }
    }
  }

  .text {
    white-space: pre-wrap;
    margin-bottom: 0;
  }

  > .user-avatar {
    flex-shrink: 0;
  }
  .signature {
    font-size: 0.85rem;
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: space-between;
    gap: 0.5rem;
    color: $locomotion-grey;
  }
  .signature-content {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 10rem;
    flex-grow: 1;
  }
  .actions {
    opacity: 0.25;
    transition: opacity 0.3s;
  }

  &:hover,
  &:focus-within {
    .actions {
      opacity: 1;
    }
  }
}
</style>
