import dayjs from "dayjs";
import { isAdminOfCommunity } from "./communities";
import { isCoownerOrOwner } from "./loanables";
import { isGlobalAdmin } from "./users";

export function isBorrower(user, loan) {
  return loan.borrower_user.id === user.id;
}

export function isLoanCommunityAdmin(user, loan) {
  if (!loan) {
    return false;
  }

  return isAdminOfCommunity(user, loan.community);
}

/**
 * Returns true if user is either admin of loan community or global admin.
 */
export function isLoanAdmin(user, loan) {
  return isGlobalAdmin(user) || isLoanCommunityAdmin(user, loan);
}

export function canViewLoan(user, loan) {
  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canViewLoanAdminDetails(user, loan) {
  return isLoanAdmin(user, loan);
}

export function canViewLoanInstructions(user, loan) {
  return (
    isCoownerOrOwner(user, loan.loanable) ||
    isLoanAdmin(user, loan) ||
    (isBorrower(user, loan) && loan.intention?.status === "completed")
  );
}

export function canAcceptLoan(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.intention?.status !== "in_process") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canRejectLoan(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.intention?.status !== "in_process") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function loanBlockedByIncident(loan, incident) {
  return (
    incident.status === "in_process" &&
    incident.blocking_until &&
    dayjs(incident.blocking_until).isAfter(loan.departure_at) &&
    dayjs(incident.start_at).isSameOrBefore(loan.departure_at)
  );
}

export function loanableHasIncidentBlockingLoan(loanable, loan) {
  return (
    loanable.active_incidents?.filter((incident) => loanBlockedByIncident(loan, incident)).length >
    0
  );
}

export function canCancelLoan(user, loan, currentTime) {
  if (loan.status !== "in_process" || loan.payment?.status === "completed") {
    return false;
  }

  if (isLoanAdmin(user, loan)) {
    return true;
  }

  if (
    !loan.is_free &&
    loan.takeover?.status === "completed" &&
    !currentTime.isBefore(loan.departure_at, "minute") &&
    // Can cancel loan if it is blocked by an incident. This handles cases where user has prepaid a
    // loan and takeover was completed (maybe automatically) and time has slipped by before they
    // realised that an incident was preventing this loan.
    !loanableHasIncidentBlockingLoan(loan.loanable, loan)
  ) {
    return false;
  }
  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable);
}

export function canResumeLoan(user, loan) {
  if (
    loan.loanable.deleted_at ||
    loan.loanable.owner.user.deleted_at ||
    loan.borrower_user.deleted_at
  ) {
    return false;
  }

  if (loan.status !== "canceled") {
    return false;
  }

  return isLoanAdmin(user, loan);
}

export function canDeclareExtension(user, loan, currentTime) {
  if (loan.status !== "in_process") {
    return false;
  }

  // Until the loan start, we can modify it directly instead of asking for an extension.
  if (currentTime.isBefore(loan.departure_at, "minute")) {
    return false;
  }

  return isBorrower(user, loan) || isLoanAdmin(user, loan);
}

export function canAcceptExtension(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canRejectExtension(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  return isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canCancelExtension(user, loan) {
  return isBorrower(user, loan) || isLoanAdmin(user, loan);
}

export function canDeclareIncident(user, loan) {
  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canResolveIncident(user, loan) {
  return isLoanAdmin(user, loan);
}

export function canChangeTakeoverInfo(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  // Allows admins to correct takeover info when contested.
  if (isLoanAdmin(user, loan)) {
    return true;
  }

  if (loan.takeover?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable);
}

export function canContestTakeoverInfo(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.takeover?.status !== "completed") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canChangeHandoverInfo(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  // Allows admins to correct handover info when contested.
  if (isLoanAdmin(user, loan)) {
    return true;
  }

  if (loan.handover?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable);
}

export function canContestHandoverInfo(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.handover?.status !== "completed") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}

export function canResolveContestedLoan(user, loan) {
  if (!(loan.handover?.status === "canceled" || loan.takeover?.status === "canceled")) {
    return false;
  }

  return isLoanAdmin(user, loan);
}

export function canValidateLoan(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.handover?.status !== "completed" && loan.takeover?.status !== "completed") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isGlobalAdmin(user);
}

export function canPrepay(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.pre_payment?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isLoanAdmin(user, loan);
}

export function canPay(user, loan) {
  if (loan.status !== "in_process") {
    return false;
  }

  if (loan.payment?.status !== "in_process") {
    return false;
  }

  return isBorrower(user, loan) || isCoownerOrOwner(user, loan.loanable) || isLoanAdmin(user, loan);
}
