var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-page',{attrs:{"fluid":"lg","name":"loan","loading":!_vm.routeDataLoaded || !_vm.itemLoaded || !_vm.loanableLoaded}},[(_vm.routeDataLoaded && _vm.itemLoaded && _vm.loanableLoaded)?_c('div',[_c('vue-headful',{attrs:{"title":_vm.fullTitle}}),_c('loan-header',{staticClass:"mb-5",attrs:{"loan":_vm.item,"user":_vm.user}}),_c('b-row',[_c('b-col',{staticClass:"loan__actions",attrs:{"lg":"8"}},[_c('b-row',[_c('b-col',{staticClass:"d-lg-none",attrs:{"md":"3"}},[_c('div',{staticClass:"mb-3"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#loan_details'),expression:"'#loan_details'"}],attrs:{"href":"#","to":"#detail"}},[_vm._v("Détails de l'emprunt")])])]),_c('b-col',{attrs:{"md":"9","lg":"12"}},[_c('loan-action-buttons',{staticClass:"mb-3",attrs:{"item":_vm.item,"cancel-loan-function":_vm.cancelLoan},on:{"extension":_vm.addExtension,"incident":function($event){return _vm.addIncident('accident')}}})],1)],1),(_vm.meaningfulIncidents && _vm.meaningfulIncidents.length > 0)?_c('loan-incidents',{attrs:{"loan":_vm.item,"incidents":_vm.meaningfulIncidents}}):_vm._e(),(
            _vm.item.id &&
            _vm.item.status === 'in_process' &&
            _vm.hasReachedStep('intention') &&
            !_vm.hasReachedStep('takeover') &&
            _vm.userIsBorrower
          )?_c('b-alert',{attrs:{"variant":"success","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("loan_approved"))+" ")]),(_vm.isAfterDeparture)?[_c('strong',[_vm._v(_vm._s(_vm.$t("modification_warning.title"))+": ")]),_vm._v(" "+_vm._s(_vm.$t("modification_warning.need_cancel"))+" ")]:[_c('b-link',{on:{"click":_vm.scrollToForm}},[_vm._v(_vm._s(_vm.$t("modification_warning.can_modify")))])]],2):_vm._e(),(
            _vm.item.loanable.type === 'car' &&
            !_vm.loanIsCanceled &&
            !_vm.hasReachedStep('handover') &&
            _vm.userIsBorrower
          )?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t("insurance_warning.title"))+" ")]),_c('i18n',{attrs:{"path":"insurance_warning.warning","tag":"p"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('a',{attrs:{"href":_vm.$t('insurance_warning.terms_link'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("insurance_warning.terms")))])]},proxy:true},{key:"emphasis",fn:function(){return [_c('strong',[_vm._v(" "+_vm._s(_vm.$t("insurance_warning.car_stays_in_quebec"))+" ")])]},proxy:true}],null,false,4131452042)})],1):_vm._e(),_c('div',{staticClass:"position-relative"},[_c('loan-actions',{staticClass:"loan-actions",class:{ loading: _vm.loading },attrs:{"item":_vm.item,"user":_vm.user},on:{"load":_vm.loadItemAndUser,"submit":_vm.submitLoan}}),(_vm.loading)?_c('layout-loading',{staticClass:"actions-loading-spinner"}):_vm._e()],1),(
            _vm.userIsBorrower &&
            (!!_vm.item.borrower_validated_at || _vm.item.status === 'completed') &&
            _vm.item.applicable_amount_types.contributions !== 'not_applicable'
          )?_c('awareness-banner',{staticClass:"loan_contribution_thanks",attrs:{"title":_vm.item.platform_tip > 0
              ? 'Merci pour votre contribution au projet'
              : 'Profitez et contribuez ...à votre façon',"image":'/coeur-du-partage-locomotion.svg'}},[(_vm.item.platform_tip > 0)?[_c('p',[_vm._v(" Grâce à vous, LocoMotion continue sa route vers ses rêves : des quartiers apaisés, des inégalités atténuées et des possibilités pour se libérer de vies centrées sur l’auto privée. ")]),_c('p',[_vm._v(" En plus des dons comme le vôtre, le réseau vit aussi grâce à des communautés vivantes et près d’une centaine de bénévoles. ")]),_c('p',[_vm._v("Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!")]),_c('div',[_c('icon-button',{attrs:{"variant":"primary","href":"https://info.locomotion.app/contribuer/"}},[_vm._v(" En savoir plus ")])],1)]:[_c('p',[_vm._v(" Le réseau LocoMotion roule grâce aux dons, mais aussi grâce à des bénévoles aux cœurs engagés! ")]),_c('p',[_vm._v(" Vous pourriez, par exemple, décider en comité des véhicules à partager, présenter le projet, coder en PHP ou encore fabriquer des remorques… ")]),_c('p',[_vm._v("Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!")]),_c('div',[_c('icon-button',{attrs:{"variant":"primary","href":"https://info.locomotion.app/contribuer/"}},[_vm._v(" Contribuer ")])],1)]],2):_vm._e()],1),_c('b-col',{staticClass:"loan__sidebar",attrs:{"id":"loan_details","lg":"4"}},[_c('loan-details-box',{attrs:{"vertical":"","loan":_vm.item,"loanable":_vm.item.loanable}})],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }