import Check from "@/assets/svg/check.svg";
import Danger from "@/assets/svg/danger.svg";
import Waiting from "@/assets/svg/waiting.svg";

import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower, isLoanAdmin } from "@/helpers/permissions/loans";

export default {
  components: {
    "svg-check": Check,
    "svg-danger": Danger,
    "svg-waiting": Waiting,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    let borrower = isBorrower(this.user, this.item);
    let coownerOrOwner = isCoownerOrOwner(this.user, this.item.loanable);
    return {
      actionLoading: false,
      isBorrower: borrower,
      isCoownerOrOwner: coownerOrOwner,
      isLoanAdmin: isLoanAdmin(this.user, this.item),
      isParticipant: borrower || coownerOrOwner,
    };
  },
  computed: {
    borrowerUser() {
      return this.item.borrower_user;
    },
    isAvailable() {
      return this.$store.state.loans.item.isAvailable;
    },
    /*
       Conditions on loan actions so they are contestable:
         - the loanable must not be self service;
         - action is either handover or takeover;
         - action must be executed and not canceled.
     */
    isContestable() {
      return !this.isSelfService && !!this.action.executed_at && this.action.status !== "canceled";
    },
    isContested() {
      return !this.isSelfService && !!this.action.executed_at && this.action.status === "canceled";
    },
    owner() {
      return this.item.loanable.owner;
    },
    loanIsCanceled() {
      return !!this.item.canceled_at;
    },
  },
};
