<template>
  <b-container v-if="itemLoaded && routeDataLoaded" fluid class="admin-community">
    <b-row>
      <b-col>
        <h1 v-if="item.name">{{ item.name }}</h1>
        <h1 v-else>
          <em>{{ $tc("community", 1) | capitalize }}</em>
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="observer" v-slot="{ passes }">
          <b-form class="form" @submit.prevent="passes(submit)">
            <div v-if="item.id" class="form__section">
              <a id="members" />
              <community-users-list
                :id="`admin-community-users-table-${item.id}`"
                ref="userTable"
                class="mb-3"
                :hide-fields="['community.name', 'community_id', 'updated_at']"
                :fetch-params="{ community_id: item.id, for: 'admin' }"
                :community-id="item.id"
                label="membres"
                show-mailbox-export
              >
                <template #head-buttons>
                  <relation-input
                    v-if="canAddCommunityUser"
                    placeholder="Ajouter un membre"
                    class="add-user-input"
                    :value="null"
                    reset-after-select
                    :query="{
                      slug: 'users',
                      value: 'id',
                      text: 'full_name',
                      params: {
                        '!communities.id': item.id,
                      },
                      details: 'email',
                    }"
                    name="add-member"
                    @input="addUser"
                  />
                </template>
              </community-users-list>

              <h2>Inviter à rejoindre la communauté</h2>
              <b-input-group>
                <multiple-email-input
                  v-model="inviteEmails"
                  placeholder="ex.: la.voisine@example.com"
                  class="flex-grow-1"
                  @submit="redirectToNewInvitation"
                />
                <b-input-group-append>
                  <icon-button
                    variant="outline-success"
                    icon="arrow-right-square"
                    @click="redirectToNewInvitation"
                  >
                  </icon-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="form__section">
              <h2>Informations générales</h2>

              <conditional-context
                :show="!canEditCommunityConfig"
                label="Réservé aux admins globaux"
              >
                <forms-builder
                  v-model="item"
                  entity="communities"
                  :definition="form"
                  :fields="['name', 'type']"
                  :disabled="!canEditCommunityConfig"
                />
              </conditional-context>

              <forms-builder
                v-model="item"
                :definition="form"
                :fields="['starting_guide_url', 'chat_group_url', 'contact_email', 'description']"
                entity="communities"
              />
            </div>

            <div class="form__section">
              <h2>Partage</h2>

              <conditional-context
                :show="!canEditCommunityConfig"
                label="Réservé aux admins globaux"
              >
                <b-form-group
                  label="Quels types de véhicules peuvent être partagés dans votre communauté."
                  label-for="area"
                >
                  <b-form-checkbox-group
                    :disabled="!canEditCommunityConfig"
                    :checked="(item.allowed_loanable_types || []).map((l) => l.id)"
                    name="allowed_loanable_types"
                    :options="allLoanableTypes"
                    @change="changeLoanableTypes"
                  />
                </b-form-group>

                <forms-builder
                  v-model="item"
                  :definition="form"
                  :disabled="!canEditCommunityConfig"
                  disabled-reason="Réservé aux admins globaux"
                  :fields="['uses_noke', 'exempt_from_contributions']"
                  entity="communities"
                />
              </conditional-context>

              <forms-builder
                v-model="item"
                :definition="form"
                :fields="['requires_identity_proof']"
                entity="communities"
              />
            </div>

            <form-section
              :inititally-visible="!item.id"
              section-title="Zone géographique"
              toggleable
            >
              <conditional-context
                :show="!canEditCommunityConfig"
                label="Réservé aux admins globaux"
              >
                <simple-map class="community-map" :polygons="communityPolygons" />

                <b-form-group
                  class="monospace"
                  description="Zone géographique sous forme de GeoJson avec un MultiPolygon"
                  label-for="area"
                >
                  <b-form-textarea
                    id="area"
                    v-model="area"
                    :disabled="!canEditCommunityConfig"
                    name="area"
                    rows="6"
                    max-rows="12"
                  />
                </b-form-group>
              </conditional-context>
            </form-section>
            <form-buttons :changed="changed" :saving="loading" @reset="reset" />

            <pricing-table
              v-if="id"
              :community-id="id"
              :available-loanable-types="availableLoanableTypes"
              :exempt-from-contributions="item.exempt_from_contributions"
              :is-private-community="item.type === 'private'"
            />
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-container>
  <layout-loading v-else />
</template>

<script>
import CommunityUsersList from "@/components/Community/CommunityUsersList.vue";

import FormsBuilder from "@/components/Forms/Builder.vue";
import MultipleEmailInput from "@/components/Forms/MultipleEmailInput.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import FormSection from "@/components/Loanable/FormSection.vue";
import SimpleMap from "@/components/Loanable/SimpleMap.vue";
import PricingTable from "@/components/Pricing/PricingTable.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import FormButtons from "@/components/shared/FormButtons.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { capitalize } from "@/helpers/filters";
import { extractMultipolygonPaths } from "@/helpers/geoJson";
import { isGlobalAdmin } from "@/helpers/permissions/users";

import locales from "@/locales";
import DataRouteGuards from "@/mixins/DataRouteGuards";

import FormMixin from "@/mixins/FormMixin";
import { urlencode } from "locutus/php/url";

export default {
  name: "AdminCommunity",
  components: {
    IconButton,
    PricingTable,
    FormButtons,
    RelationInput,
    MultipleEmailInput,
    ConditionalContext,
    FormSection,
    CommunityUsersList,
    FormsBuilder,
    SimpleMap,
  },
  mixins: [DataRouteGuards, FormMixin],
  data() {
    return {
      inviteEmails: "",
      mapOptions: {
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 18,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
      },
      polygonOptions: {
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        zIndex: 2,
      },
      ignoreFields: ["pricings"],
    };
  },
  computed: {
    canEditCommunityConfig() {
      return isGlobalAdmin(this.$store.state.user);
    },
    canAddCommunityUser() {
      return isGlobalAdmin(this.$store.state.user);
    },
    // For form mixin, only keeps these fields in API requests
    requestFieldsAllowlist() {
      if (this.canEditCommunityConfig) {
        return [
          "id",
          "chat_group_url",
          "description",
          "contact_email",
          "requires_identity_proof",
          "starting_guide_url",
          "pricings",
          "name",
          "type",
          "uses_noke",
          "exempt_from_contributions",
          "allowed_loanable_types",
          "area",
        ];
      }
      return [
        "id",
        "chat_group_url",
        "description",
        "contact_email",
        "description",
        "contact_email",
        "requires_identity_proof",
        "starting_guide_url",
        "pricings",
      ];
    },
    area: {
      get() {
        return this.item.area ? JSON.stringify(this.item?.area) : "";
      },
      set(value) {
        try {
          this.item.area = value && value !== "" ? JSON.parse(value) : null;
        } catch (e) {
          // do nothing if value is not valid JSON. This means the form-input data will only
          // be saved to the js object when it is valid json.
        }
      },
    },
    communityPolygons() {
      if (!this.item?.area?.coordinates) {
        return [];
      }

      return extractMultipolygonPaths(this.item?.area);
    },
    allLoanableTypes() {
      return this.$store.state.loanableTypes.types.map((t) => ({
        text: capitalize(this.$t(`loanable_types.${t.name}`)),
        value: t.id,
      }));
    },
    availableLoanableTypes() {
      return this.$store.state.loanableTypes.types
        .filter((t) => !!this.item?.allowed_loanable_types?.find((a) => a.id === t.id))
        .map((t) => t.name);
    },
  },
  methods: {
    redirectToNewInvitation() {
      this.$router.push(
        `/admin/invitations/new?community_id=${this.item.id}&emails=${urlencode(this.inviteEmails)}`
      );
    },
    async addUser(user) {
      if (!user) {
        return;
      }

      await this.$store.dispatch(`communities/addUser`, {
        id: this.item.id,
        data: {
          id: user.id,
        },
      });
      this.$refs.userTable.setFilters({ user_id: user.id });
    },
    changeLoanableTypes(types) {
      this.$store.commit(`${this.slug}/item`, {
        ...this.item,
        allowed_loanable_types: types.map((t) => ({ id: t })),
      });
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.communities,
        ...locales.en.forms,
        pricings: locales.en.pricings,
        loanable_types: locales.en.loanables.fields.types,
      },
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
        pricings: locales.fr.pricings,
        loanable_types: locales.fr.loanables.fields.types,
      },
    },
  },
};
</script>

<style lang="scss">
.admin-community {
  .simple-map {
    width: 100%;
    height: 20rem;
  }

  .add-user-input {
    min-width: 15rem;
  }
}
</style>
