<template>
  <div class="borrower-form">
    <validation-observer ref="observer" v-slot="{ passes }">
      <b-form :novalidate="true" class="borrower-form__form" @submit.stop.prevent="passes(submit)">
        <b-row>
          <b-col>
            <forms-validated-input
              v-model="borrower.drivers_license_number"
              name="drivers_license_number"
              type="text"
              :disabled="disabled"
              :label="$t('fields.drivers_license_number') | capitalize"
              :placeholder="placeholderOrLabel('drivers_license_number') | capitalize"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <forms-validated-input
              v-model="hasNotBeenSuedLastTenYears"
              name="has_not_been_sued_last_ten_years"
              type="checkbox"
              :disabled="disabled"
              label="Je confirme n'avoir reçu aucune poursuite au cours des 10 dernières années"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <forms-files-uploader
              v-model="borrower.saaq"
              field="saaq"
              :disabled="disabled"
              :label="$t('fields.saaq') | capitalize"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <forms-files-uploader
              v-model="borrower.gaa"
              field="gaa"
              :disabled="disabled"
              :label="$t('fields.gaa') | capitalize"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div>
              <p>Vous ne les avez pas sous la main ? Pas de soucis.</p>

              <ul>
                <li>
                  <a :href="saaqUrl" target="_blank">
                    Pour commander votre dossier de conduite SAAQ
                  </a>
                </li>
                <li>
                  <a :href="gaaUrl" target="_blank">
                    Pour commander votre rapport de sinistre GAA
                  </a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>

        <icon-button role="send" type="submit" :disabled="!changed || !isComplete || loading">
          {{ $t("Soumettre") | capitalize }}
        </icon-button>
        &nbsp;
        <icon-button
          v-if="!borrower.submitted_at"
          variant="link"
          type="submit"
          :disabled="!changed || loading"
        >
          {{ $t("save") | capitalize }} sans soumettre
        </icon-button>
        <layout-loading v-if="loading" with-button />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import FormsFilesUploader from "@/components/Forms/FilesUploader.vue";
import IconButton from "@/components/shared/IconButton.vue";

import FormLabelsMixin from "@/mixins/FormLabelsMixin";

import locales from "@/locales";
import LayoutLoading from "@/components/Layout/Loading.vue";

export default {
  name: "BorrowerForm",
  components: {
    IconButton,
    LayoutLoading,
    FormsValidatedInput,
    FormsFilesUploader,
  },
  mixins: [FormLabelsMixin],
  props: {
    borrower: {
      type: Object,
      required: true,
    },
    changed: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      gaaUrl: "https://mondossier.gaa.qc.ca/fr/DemandeWeb/DemandeReleve/",
      saaqUrl: "https://saaq.gouv.qc.ca/permis-conduire/obtenir-dossier-conduite",
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.borrowers,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.borrowers,
        ...locales.fr.forms,
      },
    },
  },
  computed: {
    isComplete() {
      return (
        this.borrower.drivers_license_number &&
        this.borrower.has_not_been_sued_last_ten_years &&
        this.borrower.gaa?.length > 0 &&
        this.borrower.saaq?.length > 0
      );
    },
    hasNotBeenSuedLastTenYears: {
      get() {
        return this.borrower.has_not_been_sued_last_ten_years;
      },
      set(val) {
        this.borrower.has_not_been_sued_last_ten_years = val;
      },
    },
  },
  methods: {
    submit(...params) {
      if (this.isComplete) {
        this.$emit("submit");
      } else {
        this.$emit("save", ...params);
      }
    },
  },
};
</script>

<style lang="scss"></style>
