<template>
  <layout-page :name="`community-view community-view--${view}`" :loading="!routeDataLoaded" fluid>
    <div :class="`community-view__overlay ${isMap}`">
      <b-row>
        <b-col lg="4" xl="3">
          <!-- loan search form container -->
          <b-card class="community-view__search-container">
            <div class="community-view__search-menu">
              <search-form
                v-if="loan"
                :item="loan"
                :possible-loanable-types="user.available_loanable_types"
                :selected-loanable-types="selectedLoanableTypes"
                :loading="loading"
                :form="loanForm"
                @selectLoanableTypes="selectLoanableTypes"
                @selectLoanable="selectLoanable"
                @changed="resetAvailabilities"
                @submit="searchLoanables"
              />
            </div>
          </b-card>
          <!---->
        </b-col>

        <!-- Results header -->
        <b-col lg="8" xl="9" class="mt-4 mt-lg-0">
          <!-- Large Screens -->
          <div v-if="view === 'list'">
            <div class="my-4">
              <!-- results header -->
              <h3>Résultats</h3>
              <!---->
              <!-- button to view map  -->
              <div class="community-view__button-container">
                <icon-button variant="white-secondary" pill @click="gotoView('map')">
                  <div class="community-view__button-spacing">
                    <div><svg-map /></div>
                    <div>Afficher la carte</div>
                  </div>
                </icon-button>
              </div>
              <!---->
            </div>
          </div>
          <!-- -->

          <!-- results display (loanable cards) -->
          <loanable-list
            v-if="view === 'list'"
            :data="loanables"
            :loading="loading"
            @select="selectLoanable"
            @test="searchLoanables"
          />
          <!---->
        </b-col>
      </b-row>
    </div>
    <!-- map display -->
    <b-row v-show="view === 'map'">
      <loanable-map
        :loanables="loanables"
        :communities="user.communities"
        :padding-client-width-min="992"
        :padding="{
          x: -150,
        }"
        @test="searchLoanables"
        @select="selectLoanable"
      >
        <template #top-right-button>
          <icon-button variant="white-secondary" pill @click="gotoView('list')">
            <div class="community-view__button-spacing">
              <div><svg-list /></div>
              <div>Afficher la liste</div>
            </div>
          </icon-button>
        </template>
      </loanable-map>
    </b-row>
    <!---->
  </layout-page>
</template>
<script>
import ListIcon from "@/assets/svg/list.svg";
import MapIcon from "@/assets/svg/map.svg";
import SearchForm from "@/components/Loan/SearchForm.vue";

import LoanableList from "@/components/Loanable/List.vue";
import LoanableMap from "@/components/Loanable/Map.vue";
import IconButton from "@/components/shared/IconButton.vue";
import Authenticated, { mustBeAuthenticated } from "@/mixins/Authenticated";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import UserMixin from "@/mixins/UserMixin";
import store from "@/store";

export default {
  name: "LoanableSearch",
  components: {
    IconButton,
    LoanableMap,
    LoanableList,
    SearchForm,
    "svg-list": ListIcon,
    "svg-map": MapIcon,
  },
  mixins: [Authenticated, DataRouteGuards, UserMixin],
  beforeRouteEnter(to, from, next) {
    mustBeAuthenticated(to, from, () => {
      if (
        store.state.user.communities.filter((c) => !!c.approved_at && !c.suspended_at).length === 0
      ) {
        store.commit("addNotification", {
          content:
            "Il faut avoir été accepté dans un quartier pour faire une recherche de véhicule",
          title: "En attente de validation",
          variant: "warning",
          type: "loan",
        });
        next("/app");
      }
      next();
    });
  },
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  computed: {
    loanables() {
      return this.$store.getters["loanable.search/loanables"];
    },
    loading() {
      return this.$store.getters["loanable.search/loading"];
    },
    isMap() {
      return this.view === "map" ? "community-list--no-pointer-events" : "";
    },
    loan() {
      return this.$store.state.loans.item;
    },
    loanForm() {
      return this.$store.state.loans.form;
    },
    loanableForm() {
      return this.$store.state.loanables.form;
    },
    selectedLoanableTypes() {
      return this.$store.state["loanable.search"].selectedLoanableTypes;
    },
  },
  mounted() {
    let selectedTypes = [];
    const possibleTypes = this.user.available_loanable_types;
    const urlTypes = this.$route.query["types"];
    if (urlTypes) {
      selectedTypes = urlTypes.split(",").filter((urlType) => possibleTypes.includes(urlType));
    } else if (this.selectedLoanableTypes.length > 0) {
      selectedTypes = this.selectedLoanableTypes.filter((urlType) =>
        possibleTypes.includes(urlType)
      );
    } else {
      if (!this.user.borrower || !this.user.borrower.validated) {
        selectedTypes = possibleTypes.filter((t) => t !== "car");
      } else {
        selectedTypes = possibleTypes;
      }
    }
    this.updateSelectedTypes(selectedTypes);
  },
  methods: {
    gotoView(view) {
      this.$router.push(`/search/${view}`);
    },
    resetAvailabilities() {
      this.$store.commit(`loanable.search/resetAvailabilities`);
    },
    selectLoanableTypes(value) {
      this.updateSelectedTypes(value.filter((item, i, ar) => ar.indexOf(item) === i));
    },
    async selectLoanable(loanable) {
      this.$store.commit("loans/patchItem", {
        borrower_user: this.user,
        loanable,
        loanable_id: loanable.id,
      });

      this.$router.push("/loans/new");
    },
    async searchLoanables() {
      await this.$store.dispatch("loanable.search/testAll", {
        loan: this.loan,
      });
    },
    updateSelectedTypes(selectedTypes) {
      const types = selectedTypes.join(",");

      if (this.$route.query.types !== types) {
        const query = {
          ...this.$route.query,
          types,
        };

        this.$router.replace({ ...this.$route, query });
      }
      this.$store.dispatch("loanable.search/list", { types: selectedTypes });
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.community-view {
  &__overlay {
    z-index: 20;
    position: relative;
  }

  &__button-modify-search {
    color: $primary !important;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  &__button-container {
    pointer-events: all;
    position: absolute;
    right: 15px;
    top: 1.5rem;
  }

  &__button-spacing {
    display: flex;
    flex-direction: row;
  }

  &__button-spacing > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }

  // Go to map & list buttons
  .btn-secondary {
    color: #7a7a7a;
    background: #fff;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    margin: 0;

    &:hover {
      color: #7a7a7a;
      background: #fff;
      border: 1px solid #e5e5e5;
    }
  }

  &__search-container {
    margin-top: 1.5rem;
    pointer-events: all;
    /* Allow calendar to be visible when overflowing the card.
     Making this visible, improves the UI for the calendar, but fails to show a scrollbar when
     necessary for smaller screens*/
    overflow: auto;
  }

  h3 {
    font-weight: 700;

    @include media-breakpoint-down(md) {
      line-height: $h4-line-height;
      font-size: $h4-font-size;
    }
  }

  .community-map {
    height: 100vh;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - #{$layout-navbar-height});
      z-index: 10;
    }
  }
}

.community-view--map {
  .page__content {
    position: relative;
  }
  .community-view__search-container {
    @include media-breakpoint-up(lg) {
      max-height: calc(100vh - #{$layout-navbar-height} - 3rem);
      box-shadow: $small-shadow;
    }
  }
}

.community-list--no-pointer-events {
  pointer-events: none;
}

.community-view--margin-top {
  margin-top: 20px;
}
</style>
