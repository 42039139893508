<template>
  <vue2-date-picker
    ref="datePicker"
    type="date"
    :class="{
      'forms-datepicker': true,
      'is-valid': state === true,
      'is-invalid': state === false,
      'fit-width': fitWidth,
    }"
    :inline="inline"
    :default-value="openDate"
    :clearable="clearButton"
    :input-class="formControlClass"
    :disabled-date="disabledDatesFct"
    :disabled="disabled"
    :value="dateValue"
    :multiple="multiple"
    @input="emitInput"
  />
</template>

<script>
import dayjs from "dayjs";
import Datepicker from "vue2-datepicker";

export default {
  name: "FormsDatePicker",
  components: { vue2DatePicker: Datepicker },
  props: {
    clearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledDatesFct: {
      type: Function,
      required: false,
      default: () => false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Force this date to be shown.
    openDate: {
      type: Date,
      required: false,
      default() {
        return new Date();
      },
    },
    selected: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    state: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    value: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      required: false,
      default: undefined,
    },
    fitWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateValue() {
      if (!this.value || this.value === "null") {
        return null;
      }

      if (Array.isArray(this.value)) {
        return this.value.map((v) => dayjs(v).toDate());
      }

      return dayjs(this.value).toDate();
    },
    formControlClass() {
      if (!this.size) {
        return "form-control";
      }
      return "form-control form-control-" + this.size;
    },
  },
  methods: {
    emitInput(value) {
      if (!this.disabled) {
        if (!value) {
          this.$emit("input", null);
          return;
        }

        if (Array.isArray(value)) {
          this.$emit(
            "input",
            value.map((v) => v.format("YYYY-MM-DD"))
          );
          return;
        }
        return this.$emit("input", value.format("YYYY-MM-DD"));
      }
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.forms-datepicker {
  input.form-control[readonly]:not([disabled]) {
    background-color: $white;
  }

  &.fit-width .vdp-datepicker__calendar {
    width: 100%;
  }

  .vdp-datepicker__calendar {
    @include media-breakpoint-down(sm) {
      min-width: 300px;
    }
  }

  .vdp-datepicker__clear-button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px;
    transition: transform $one-tick ease-in-out;

    &:hover {
      transform: scale(1.4);
    }
  }
}
</style>
