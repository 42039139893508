<template>
  <div class="loan-actions">
    <loan-form :item="item" :open="isCurrentStep('creation')" :user="user" @submit="emitSubmit" />

    <div class="loan-actions__action">
      <div v-if="item.intention && !item.is_self_service">
        <loan-actions-intention
          :action="item.intention"
          :item="item"
          :open="isCurrentStep('intention')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
      </div>

      <div v-if="item.pre_payment && showPrepayment">
        <loan-actions-pre-payment
          :action="item.pre_payment"
          :item="item"
          :open="isCurrentStep('pre_payment')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
      </div>

      <div v-if="item.takeover && item.requires_compensation_factors">
        <loan-actions-takeover
          :action="item.takeover"
          :item="item"
          :open="isCurrentStep('takeover')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
      </div>

      <loan-actions-extension
        v-for="extension in item.extensions"
        :key="extension.key"
        :open="!extension.executed_at"
        :action="extension"
        :item="item"
        :user="user"
        @created="emitLoad"
        @completed="emitLoad"
        @canceled="emitLoad"
        @rejected="emitLoad"
      />

      <template v-if="item.handover">
        <loan-actions-handover
          v-if="item.requires_compensation_factors"
          :action="item.handover"
          :item="item"
          :open="isCurrentStep('handover')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
        <loan-actions-ongoing
          v-else
          :action="item.handover"
          :item="item"
          :open="isCurrentStep('handover')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
      </template>

      <div v-if="item.payment">
        <loan-actions-payment
          :action="item.payment"
          :item="item"
          :open="isCurrentStep('payment')"
          :user="user"
          @completed="emitLoad"
          @canceled="emitLoad"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoanActionsExtension from "@/components/Loan/Actions/Extension.vue";
import LoanActionsHandover from "@/components/Loan/Actions/Handover.vue";
import LoanActionsIntention from "@/components/Loan/Actions/Intention.vue";
import LoanActionsOngoing from "@/components/Loan/Actions/Ongoing.vue";
import LoanActionsPayment from "@/components/Loan/Actions/Payment.vue";
import LoanActionsPrePayment from "@/components/Loan/Actions/PrePayment.vue";
import LoanActionsTakeover from "@/components/Loan/Actions/Takeover.vue";
import LoanForm from "@/components/Loan/Form.vue";

import LoanStepsSequence from "@/mixins/LoanStepsSequence";

export default {
  name: "LoanActions",
  components: {
    LoanForm,
    LoanActionsExtension,
    LoanActionsHandover,
    LoanActionsOngoing,
    LoanActionsIntention,
    LoanActionsPayment,
    LoanActionsPrePayment,
    LoanActionsTakeover,
  },
  mixins: [LoanStepsSequence],
  props: {
    item: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showPrepayment() {
      // Only show prepayment if loan is not free or user can contribute
      return (
        (this.item.pre_payment &&
          this.item.applicable_amount_types.contributions !== "not_applicable") ||
        !this.item.is_free ||
        // In cases where the user has been granted a subscription before the takeover was created
        // the contribtion becomes not_applicable. We should still show the takeover so it can
        // be skipped.
        !this.item.takeover
      );
    },
  },
  methods: {
    emitLoad() {
      this.$emit("load");
    },
    emitSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss">
.loan-actions.card {
  margin-bottom: 20px;
  box-shadow: $small-shadow;
}

.loan-actions .tiny-invoice {
  .invoice-contribution {
    th,
    td {
      color: $locomotion-light-green;
    }
  }
  .invoice-total {
    th,
    td {
      font-weight: bold;
      border: none;
    }
    td {
      font-size: 1.5rem;
    }
  }
}
</style>
