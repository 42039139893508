<template>
  <b-container v-if="routeDataLoaded" fluid>
    <template v-if="loanable">
      <vue-headful :title="fullTitle" />

      <b-row class="mb-4">
        <b-col>
          <h1 v-if="loanable.name">{{ loanable.name }}</h1>
          <h1 v-else>
            <em>{{ $tc("loanable", 1) | capitalize }}</em>
          </h1>
        </b-col>
      </b-row>
    </template>

    <owner-form
      v-if="loanable && !loanable.id && !loanable.owner"
      :loanable="loanable"
      changing-owner
      no-cancel
    />

    <loanable-form v-show="loanable && loanable.owner" :id="id" />
  </b-container>
  <layout-loading v-else />
</template>

<script>
import LoanableForm from "@/components/Loanable/LoanableForm.vue";
import OwnerForm from "@/components/Loanable/OwnerForm.vue";

import { capitalize } from "@/helpers/filters";

import locales from "@/locales";

import DataRouteGuards from "@/mixins/DataRouteGuards";

export default {
  name: "AdminLoanable",
  components: {
    OwnerForm,
    LoanableForm,
  },
  mixins: [DataRouteGuards],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ignoreFields: ["coowners"],
    };
  },
  computed: {
    loanable() {
      return this.$store.state.loanables.item;
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("loanable", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.loanable.name || capitalize(this.$i18n.tc("loanable", 1));
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
        ...locales.en.forms,
        titles: locales.en.titles,
      },
      fr: {
        ...locales.fr.loanables,
        ...locales.fr.forms,
        titles: locales.fr.titles,
      },
    },
  },
};
</script>

<style lang="scss"></style>
