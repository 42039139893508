<template>
  <loanable-sidebar
    :loanable="loanable"
    :show-admin-links="canViewLoanAdminDetails"
    :show-instructions="canViewLoanInstructions"
    show-contacts-only
  >
    <template #center>
      <hr v-if="!canViewLoanAdminDetails && isBorrower && hasOwnerContacts" class="mt-3" />

      <template v-if="!isBorrower || canViewLoanAdminDetails">
        <dl>
          <dt>{{ $t("fields.borrower_user_id") | capitalize }}</dt>
          <dd>
            <user-blurb
              :show-admin-link="canViewLoanAdminDetails"
              :user="loan.borrower_user"
              show-phone
              show-email
            >
              <template
                v-if="
                  loanable.sharing_mode === 'hybrid' &&
                  !loan.is_self_service &&
                  !loan.is_borrower_trusted
                "
                #dropdown
              >
                <b-dropdown-item variant="success" @click="addBorrowerToTrustedBorrowers">
                  <b-icon icon="shield-fill-check" />
                  Ajouter au réseau de confiance
                </b-dropdown-item>
              </template>
              <template v-else-if="loan.is_borrower_trusted" #nameicon>
                <b-icon
                  v-b-tooltip="'Dans le réseau de confiance de ce véhicule'"
                  class="text-primary"
                  icon="shield-fill-check"
                />
              </template>
            </user-blurb>
          </dd>
        </dl>
        <hr class="mt-3" />
      </template>

      <b-alert v-if="tzDifference" variant="secondary" show class="small text-muted mb-2">
        Heure dans le fuseau horaire du véhicule: {{ loanableTimezone }}
      </b-alert>
      <b-row>
        <b-col tag="dl" cols="6">
          <dt>{{ $t("fields.departure_at") | capitalize }}</dt>
          <dd>
            {{ zonedTimes.departureAt | shortDate | capitalize }}<br />{{
              zonedTimes.departureAt | time
            }}
          </dd>
        </b-col>
        <b-col tag="dl" cols="6">
          <dt>{{ $t("fields.return_at") | capitalize }}</dt>
          <dd>
            {{ zonedTimes.returnAt | shortDate | capitalize }}<br />
            {{ zonedTimes.returnAt | time }}
          </dd>
        </b-col>
      </b-row>
      <b-row>
        <b-col tag="dl" cols="6">
          <dt>{{ $t("details_box.duration") | capitalize }}</dt>
          <dd v-if="duration > 0">
            {{
              $tc("details_box.duration_over_days", loan.calendar_days, {
                hours: durationInHours(duration),
                days: loan.calendar_days,
              })
            }}
          </dd>
        </b-col>
        <b-col v-if="!loan.is_free && distance > 0" tag="dl" cols="6">
          <dt>
            {{
              hasFinalDistance
                ? $t("details_box.distance")
                : $t("details_box.estimated_distance") | capitalize
            }}
          </dt>
          <dd>{{ distance }} km</dd>
        </b-col>

        <b-col cols="12">
          <dt class="mb-2">
            <toggle-link collapse-id="price-details" :no-toggle="isFree">
              <template v-if="isBorrower || canViewLoanAdminDetails">
                {{
                  hasPaymentCompleted
                    ? $t("details_box.cost")
                    : $t("fields.estimated_price") | capitalize
                }}<template v-if="canViewLoanAdminDetails">
                  ({{ $t("fields.borrower_user_id") }})
                </template></template
              >
              <template v-else-if="isCoownerOrOwner">
                {{
                  hasPaymentCompleted
                    ? $t("details_box.compensation")
                    : $t("details_box.estimated_compensation") | capitalize
                }}</template
              >
            </toggle-link>
          </dt>
          <dd>
            <div v-if="summaryVisible || isFree">
              <layout-loading v-if="evaluatingPrice" with-text></layout-loading>
              <template v-else>
                <div v-if="isFree && isBorrower">Gratuit!</div>
                <div v-else-if="isLoanAdmin || isBorrower">
                  {{ -borrowerTotal | currency }}
                </div>
                <div v-else-if="isCoownerOrOwner">{{ ownerTotal | currency }}</div>
              </template>
            </div>

            <b-collapse
              id="price-details"
              :visible="!summaryVisible && !isFree"
              role="tabpanel"
              accordion="price-details"
              @input="(visible) => (summaryVisible = !visible)"
            >
              <layout-loading
                v-if="evaluatingPrice"
                class="table-loading-indicator"
              ></layout-loading>

              <tiny-invoice
                v-if="isLoanAdmin || isBorrower"
                :invoice="borrowerInvoice"
                class="p-2"
              />
              <tiny-invoice v-else-if="isCoownerOrOwner" :invoice="ownerInvoice" class="p-2" />

              <div v-if="loanable.type === 'car'" class="small">
                <a href="https://info.locomotion.app/tarifs-autopartage/" target="_blank">{{
                  $t("details_box.pricing_explanation") | capitalize
                }}</a>
              </div>
            </b-collapse>
          </dd>
        </b-col>
      </b-row>
    </template>
  </loanable-sidebar>
</template>

<script>
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import LoanableSidebar from "@/components/Loanable/LoanableSidebar.vue";

import ToggleLink from "@/components/shared/ToggleLink.vue";
import UserBlurb from "@/components/User/UserBlurb.vue";
import dayjs from "@/helpers/dayjs";
import { durationInHours } from "@/helpers/filters";
import { summarizeBorrowerInvoice, summarizeOwnerInvoice } from "@/helpers/invoices";
import { getZonedTimes } from "@/helpers/loanTimezones";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import {
  canViewLoanAdminDetails,
  canViewLoanInstructions,
  isBorrower,
  isLoanAdmin,
} from "@/helpers/permissions/loans";

import locales from "@/locales";
import UserMixin from "@/mixins/UserMixin";
import { addTrustedBorrower } from "@/requests/trustedBorrowersRequests";

export default {
  name: "LoanDetailsBox",
  components: {
    TinyInvoice,
    LoanableSidebar,
    ToggleLink,
    UserBlurb,
  },
  mixins: [UserMixin],
  props: {
    loan: {
      type: Object,
      required: true,
    },
    loanable: {
      type: Object,
      required: true,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    const user = this.$store.state.user;
    return {
      summaryVisible: !canViewLoanAdminDetails(user, this.loan),
      isCoownerOrOwner: isCoownerOrOwner(user, this.loanable),
      isBorrower: isBorrower(user, this.loan),
      isLoanAdmin: isLoanAdmin(user, this.loan),
      canViewLoanAdminDetails: canViewLoanAdminDetails(user, this.loan),
      canViewLoanInstructions: canViewLoanInstructions(user, this.loan),
    };
  },
  computed: {
    isFree() {
      return (
        ((this.isBorrower || this.isLoanAdmin) && this.borrowerInvoice.items.length === 0) ||
        (this.isCoownerOrOwner && this.ownerInvoice.items.length === 0)
      );
    },
    instructions() {
      if (!this.canViewLoanInstructions) {
        return null;
      }
      return this.loanable.trusted_borrower_instructions ?? this.loanable.instructions;
    },
    zonedTimes() {
      return getZonedTimes(this.loan);
    },
    loanableTimezone() {
      return this.zonedTimes.departureAt.format("z (Z)");
    },
    tzDifference() {
      return dayjs().tz(this.loanable.timezone).format("z") !== dayjs().format("z");
    },
    duration() {
      if (this.loan.actual_duration_in_minutes) {
        return this.loan.actual_duration_in_minutes;
      }
      return this.loan.duration_in_minutes;
    },
    hasFinalDistance() {
      return this.loan.handover && this.loan.handover.mileage_end;
    },
    hasPaymentCompleted() {
      return this.loan.payment?.status === "completed";
    },
    distance() {
      if (this.hasFinalDistance) {
        return this.loan.handover.mileage_end - this.loan.takeover.mileage_beginning;
      }
      return this.loan.estimated_distance;
    },
    loanableUrl() {
      if (this.canViewLoanAdminDetails) {
        if (!this.loanable.deleted_at) {
          return "/admin/loanables/" + this.loanable.id;
        } else {
          return "/admin/loanables?is_deleted=true&id=" + this.loanable.id;
        }
      }

      return "";
    },
    borrowerInvoice() {
      return summarizeBorrowerInvoice(this.loan.borrower_invoice, {
        ownerName: this.loan.loanable.owner.user.name,
        communityName: this.loan.community?.name,
        groupCompensations: true,
        groupContributions: true,
      });
    },
    borrowerTotal() {
      return this.loan.borrower_invoice?.user_balance_change ?? 0;
    },
    ownerInvoice() {
      return summarizeOwnerInvoice(this.loan);
    },
    ownerTotal() {
      return this.loan.owner_invoice?.user_balance_change;
    },
    hasOwnerContacts() {
      return this.loanable.show_owner_as_contact || this.coownerContacts.length > 0;
    },
    evaluatingPrice() {
      return this.$store.state.loans.evaluatingPrice;
    },
    coownerContacts() {
      return this.loanable.coowners.filter((c) => c.show_as_contact);
    },
  },
  methods: {
    durationInHours,
    async addBorrowerToTrustedBorrowers() {
      await addTrustedBorrower(this.loanable.id, this.loan.borrower_user.id);
      this.$store.commit("loans/patchItem", { is_borrower_trusted: true });
    },
  },
  i18n: {
    messages: {
      en: {
        loanable: locales.en.loanables,
        bill_items: locales.en.bill_items,
        ...locales.en.loans,
      },
      fr: {
        loanable: locales.fr.loanables,
        bill_items: locales.fr.bill_items,
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss">
.loanable-details-box {
  .tiny-invoice {
    font-size: 0.8rem;
  }

  > .card {
    box-shadow: $small-shadow;
  }
  .card-img-top {
    overflow: hidden;
  }
  .archived {
    background: $eggshell;
    opacity: 0.5;
    a,
    span {
      color: $content-neutral-secondary;
    }
  }
  .user_text {
    // Show line feeds in comments, instructions and location_description
    white-space: pre-line;
  }

  dt {
    font-weight: 400;
    font-size: 0.8rem;
    color: $content-neutral-secondary;
    margin-bottom: 0.25rem;
  }
  dd {
    margin-bottom: 1rem;
  }
  dl {
    margin-bottom: 0;
  }
  hr {
    margin-top: 0;

    margin-bottom: 1rem;
  }
  .table-loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75%;
    opacity: 0.5;
  }

  .price-table {
    width: 100%;
    font-size: 0.8rem;

    &.loading {
      opacity: 0.5;
    }

    th {
      font-weight: 400;
    }
    td {
      vertical-align: top;
    }
    .spacer-row > td {
      padding-bottom: 0.5rem;
    }
    .total-row {
      & > td,
      th {
        font-weight: bold;
        padding-top: 0.5rem;
      }
    }
  }
}
</style>
