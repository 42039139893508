var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-dashboard"},[_c('h1',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("titles.dashboard"))))]),_c('incident-table',{attrs:{"initial-filters":{ status: 'in_process' }}}),_c('paginated-table',{attrs:{"id":"admin-dashboard-contestations","endpoint":"loans","extra-data":['loanable.timezone'],"fetch-params":{ contested: true, for: 'admin', status: 'in_process' },"label":"Emprunts avec contestation","columns":_vm.loanColumns,"show-generate-csv":false,"row-link":"","show-action-column":false}}),_c('community-users-list',{attrs:{"id":"admin-dashboard-user-approvals","fetch-params":{ status: 'new', for: 'admin' },"hide-fields":['role', 'status'],"initial-filters":{
      updated_at: _vm.$dayjs().subtract(2, 'month').toISOString() + '@',
      proof_state: 'unevaluated',
    },"sync-filters-with-url":false,"show-generate-csv":false,"label":"Participants à approuver"}}),(_vm.isGlobalAdmin)?_c('paginated-table',{attrs:{"id":"admin-dashboard-borrower-approvals","endpoint":"users","fetch-params":{
      'borrower.submitted_at': '@',
      '!borrower.approved_at': '@',
    },"show-generate-csv":false,"label":"Profils d'emprunteurs à approuver","columns":_vm.userColumns,"extra-filters":_vm.userExtraFilters,"show-action-column":false},on:{"row-clicked":(item) => _vm.$router.push(`/admin/users/${item.id}#borrower`)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }