import { loanBlockedByIncident } from "@/helpers/permissions/loans";
import { cancelLoan } from "@/requests/loanRequests";

export default {
  computed: {
    hasActiveExtensions() {
      if (!this.item.id) {
        return false;
      }

      // an active extension has the status "in_process"
      return this.item.extensions.reduce(
        (acc, i) => acc || !i.id || i.status === "in_process",
        false
      );
    },
    hasActiveIncidents() {
      return !!this.activeIncident;
    },
    meaningfulIncidents() {
      // If loan is in process all blocking incidents are meaningful.
      // All incidents having this loan as source are meaningful (blocking or not).
      return (
        this.item.loanable.active_incidents?.filter(
          (incident) =>
            (incident.loan_id && incident.loan_id === this.item.id) ||
            (this.item.status !== "completed" && loanBlockedByIncident(this.item, incident))
        ) ?? []
      );
    },
    activeIncident() {
      return this.meaningfulIncidents.length > 0 ? this.meaningfulIncidents[0] : null;
    },
    loanIsContested() {
      const { handover, takeover } = this.item;
      return (
        (handover && handover.status === "canceled") || (takeover && takeover.status === "canceled")
      );
    },
    loanIsCompleted() {
      return this.item.status === "completed";
    },
    loanIsCanceled() {
      return this.item.status === "canceled";
    },
    userIsOwner() {
      if (!this.item.loanable.owner) {
        return false;
      }

      return this.user.id === this.item.loanable.owner.user.id;
    },
    currentStep() {
      if (!this.item.id) {
        return "creation";
      }
      if (this.loanIsCompleted) {
        return "completed";
      }
      if (this.loanIsCanceled) {
        return "canceled";
      }
      if (this.item.takeover?.status === "canceled") {
        return "takeover";
      }
      if (this.item.handover?.status === "canceled") {
        return "handover";
      }
      if (this.hasActiveExtensions) {
        return "extension";
      }

      const inProcessSteps = ["intention", "pre_payment", "takeover", "handover", "payment"].filter(
        (step) => this.item[step]?.status === "in_process"
      );

      return inProcessSteps[0] ?? "creation";
    },
  },
  methods: {
    addExtension() {
      const handover = this.item.handover;

      if (handover) {
        let newDuration = this.item.actual_duration_in_minutes + 15;
        const inTenMinutes = this.$dayjs().add(10, "minute");

        // if return is (almost) in the past, add 15 minutes to current time as new return time.
        if (inTenMinutes.isAfter(this.item.actual_return_at, "minute")) {
          newDuration = this.$dayjs()
            .add(15, "minute")
            .diff(this.$dayjs(this.item.departure_at), "minute");
        }

        this.item.extensions.push({
          status: "in_process",
          new_duration: newDuration,
          comments_on_extension: "",
          loan_id: this.item.id,
        });
      }

      setTimeout(() => {
        const el = document.getElementById("loan-extension-new");
        this.$scrollTo(el);
      }, 10);
    },
    addIncident(type) {
      if (this.item.loanable.active_incidents.filter((i) => !i.id).length === 0) {
        this.item.loanable.active_incidents.push({
          status: "in_process",
          incident_type: type,
          loan_id: this.item.id,
        });
      }

      setTimeout(() => {
        const el = document.getElementById("loan-incident-new");
        this.$scrollTo(el);
      }, 10);
    },
    async cancelLoan() {
      await cancelLoan(this.item.id);
      await this.loadItemAndUser();
    },
    async loadItemAndUser() {
      await Promise.all([
        this.loadItem(),
        this.$store.dispatch("loadUser"),
        this.$store.dispatch("dashboard/loadLoans"),
      ]);
    },
    hasReachedStep(step) {
      const { id, intention, pre_payment, takeover, handover, payment } = this.item;

      switch (step) {
        case "creation":
          return !!id;
        case "intention":
          return intention && !!intention.executed_at;
        case "pre_payment":
          return pre_payment && !!pre_payment.executed_at;
        case "takeover":
          return takeover && !!takeover.executed_at;
        case "handover":
          return handover && !!handover.executed_at;
        case "payment":
          return payment && !!payment.executed_at;
        default:
          return false;
      }
    },
    isCurrentStep(step) {
      return this.currentStep === step;
    },
  },
};
