<template>
  <tr>
    <th>
      {{ label }}
      <small v-if="subtext" class="invoice-item-subtext">{{ subtext }}</small>
      <slot></slot>
    </th>

    <td class="text-right tabular-nums">
      <b-spinner v-if="loading" class="invoice-line-spinner" />
      {{ total | currency }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "TinyInvoiceLine",
  props: {
    label: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: false,
      default: undefined,
    },
    total: {
      type: Number,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.spinner-border.invoice-line-spinner {
  border-width: 2px;
  height: 0.875em;
  width: 0.875em;
  margin-right: 0.5rem;
  border-color: $grey;
  border-right-color: transparent;
}
</style>
