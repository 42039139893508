<script>
import SafeImage from "@/components/shared/SafeImage.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ImageCarousel",
  components: { SafeImage },
  props: {
    images: {
      type: Array,
      required: true,
    },
    aspectRatio: {
      type: String,
      default: undefined,
    },
    preferredSizes: {
      type: Array,
      default: () => ["thumbnail", "original"],
    },
  },
});
</script>

<template>
  <b-carousel v-if="images.length > 1" class="image-carousel" :interval="0" controls indicators>
    <b-carousel-slide v-for="image in images" :key="image.id">
      <safe-image :preferred-sizes="preferredSizes" :image="image" :aspect-ratio="aspectRatio" />
    </b-carousel-slide>
  </b-carousel>
  <safe-image
    v-else-if="images.length === 1"
    :preferred-sizes="preferredSizes"
    :image="images[0]"
    :aspect-ratio="aspectRatio"
  />
</template>

<style lang="scss">
.image-carousel {
  .carousel-inner {
    height: 100%;
  }

  .carousel-item {
    height: 100%;
    transition: 0.5s ease all;

    .carousel-caption {
      position: unset;
      height: 100%;
      padding: 0;
    }
  }

  .carousel-indicators {
    margin-bottom: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 25%;
    height: 50%;
  }
}
</style>
