<template>
  <layout-page fluid="lg" name="loan" :loading="!routeDataLoaded || !itemLoaded || !loanableLoaded">
    <div v-if="routeDataLoaded && itemLoaded && loanableLoaded">
      <vue-headful :title="fullTitle" />
      <loan-header class="mb-5" :loan="item" :user="user"></loan-header>

      <b-row>
        <b-col lg="8" class="loan__actions">
          <b-row>
            <b-col md="3" class="d-lg-none">
              <div class="mb-3">
                <a v-scroll-to="'#loan_details'" href="#" to="#detail">Détails de l'emprunt</a>
              </div>
            </b-col>
            <b-col md="9" lg="12">
              <loan-action-buttons
                class="mb-3"
                :item="item"
                :cancel-loan-function="cancelLoan"
                @extension="addExtension"
                @incident="addIncident('accident')"
              />
            </b-col>
          </b-row>

          <loan-incidents
            v-if="meaningfulIncidents && meaningfulIncidents.length > 0"
            :loan="item"
            :incidents="meaningfulIncidents"
          />

          <b-alert
            v-if="
              item.id &&
              item.status === 'in_process' &&
              hasReachedStep('intention') &&
              !hasReachedStep('takeover') &&
              userIsBorrower
            "
            variant="success"
            show
          >
            <h4 class="alert-heading">
              {{ $t("loan_approved") }}
            </h4>
            <template v-if="isAfterDeparture">
              <strong>{{ $t("modification_warning.title") }}: </strong>
              {{ $t("modification_warning.need_cancel") }}
            </template>
            <template v-else>
              <b-link @click="scrollToForm">{{ $t("modification_warning.can_modify") }}</b-link>
            </template>
          </b-alert>

          <b-alert
            v-if="
              item.loanable.type === 'car' &&
              !loanIsCanceled &&
              !hasReachedStep('handover') &&
              userIsBorrower
            "
            show
            variant="info"
          >
            <h4 class="alert-heading">
              {{ $t("insurance_warning.title") }}
            </h4>

            <i18n path="insurance_warning.warning" tag="p">
              <template #link>
                <a :href="$t('insurance_warning.terms_link')" target="_blank">{{
                  $t("insurance_warning.terms")
                }}</a>
              </template>
              <template #emphasis>
                <strong>
                  {{ $t("insurance_warning.car_stays_in_quebec") }}
                </strong>
              </template>
            </i18n>
          </b-alert>

          <div class="position-relative">
            <loan-actions
              class="loan-actions"
              :class="{ loading }"
              :item="item"
              :user="user"
              @load="loadItemAndUser"
              @submit="submitLoan"
            />
            <layout-loading v-if="loading" class="actions-loading-spinner"></layout-loading>
          </div>

          <awareness-banner
            v-if="
              userIsBorrower &&
              (!!item.borrower_validated_at || item.status === 'completed') &&
              item.applicable_amount_types.contributions !== 'not_applicable'
            "
            class="loan_contribution_thanks"
            :title="
              item.platform_tip > 0
                ? 'Merci pour votre contribution au projet'
                : 'Profitez et contribuez ...à votre façon'
            "
            :image="'/coeur-du-partage-locomotion.svg'"
          >
            <template v-if="item.platform_tip > 0">
              <p>
                Grâce à vous, LocoMotion continue sa route vers ses rêves&nbsp;: des quartiers
                apaisés, des inégalités atténuées et des possibilités pour se libérer de vies
                centrées sur l’auto privée.
              </p>
              <p>
                En plus des dons comme le vôtre, le réseau vit aussi grâce à des communautés
                vivantes et près d’une centaine de bénévoles.
              </p>
              <p>Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!</p>
              <div>
                <icon-button variant="primary" href="https://info.locomotion.app/contribuer/">
                  En savoir plus
                </icon-button>
              </div>
            </template>
            <template v-else>
              <p>
                Le réseau LocoMotion roule grâce aux dons, mais aussi grâce à des bénévoles aux
                cœurs engagés!
              </p>
              <p>
                Vous pourriez, par exemple, décider en comité des véhicules à partager, présenter le
                projet, coder en PHP ou encore fabriquer des remorques…
              </p>
              <p>Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!</p>
              <div>
                <icon-button variant="primary" href="https://info.locomotion.app/contribuer/">
                  Contribuer
                </icon-button>
              </div>
            </template>
          </awareness-banner>
        </b-col>

        <b-col id="loan_details" lg="4" class="loan__sidebar">
          <loan-details-box vertical :loan="item" :loanable="item.loanable" />
        </b-col>
      </b-row>
    </div>
  </layout-page>
</template>

<script>
import LoanIncidents from "@/components/Incident/LoanIncidents.vue";
import LoanActionButtons from "@/components/Loan/ActionButtons.vue";
import LoanActions from "@/components/Loan/Actions.vue";

import LoanDetailsBox from "@/components/Loan/DetailsBox.vue";
import LoanHeader from "@/components/Loan/LoanHeader.vue";
import AwarenessBanner from "@/components/shared/AwarenessBanner.vue";
import IconButton from "@/components/shared/IconButton.vue";

import { capitalize } from "@/helpers/filters";
import { isBorrower } from "@/helpers/permissions/loans";

import locales from "@/locales";

import Authenticated from "@/mixins/Authenticated";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import LoanStepsSequence from "@/mixins/LoanStepsSequence";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "LoanPage",
  components: {
    LoanIncidents,
    IconButton,
    AwarenessBanner,
    LoanActions,
    LoanActionButtons,
    LoanHeader,
    LoanDetailsBox,
  },
  mixins: [Authenticated, DataRouteGuards, FormMixin, LoanStepsSequence, UserMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.id === "new") {
        if (!vm.item?.loanable && !to.query.loanable_id) {
          // No way to load loanable: not passed from search or as query param
          vm.$router.replace("/search/map");
        } else {
          // Loanables are always loaded for new loans, since we may get here from the search page
          // which will have an incomplete loanable or with a direct link with a loanable_id
          // query param.
          vm.loadLoanable();
        }
      }
    });
  },
  data() {
    return {
      loanFormShown: this.isCurrentStep("creation"),
    };
  },
  computed: {
    loanableLoaded() {
      return !!this.$store.state.loans.item?.loanable;
    },
    fullTitle() {
      const parts = ["LocoMotion", capitalize(this.$i18n.t("titles.loans"))];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.item && this.item.loanable
        ? this.item.loanable.name
        : capitalize(this.$i18n.tc("titles.loanable", 1));
    },
    userIsBorrower() {
      return isBorrower(this.user, this.item);
    },
    isAfterDeparture() {
      return this.$second.isAfter(this.item.departure_at);
    },
  },
  watch: {
    routeDataLoaded(loaded) {
      if (loaded && this.itemLoaded) {
        setTimeout(this.scrollToActiveAction, 100);
      }
    },
    itemLoaded(loaded) {
      if (loaded && !this.loanableLoaded && this.$store.state.loanables.item) {
        this.patchLoan();
      } else if (loaded && this.routeDataLoaded) {
        setTimeout(this.scrollToActiveAction, 100);
      }
    },
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId === "loan-actions-new") {
        this.loanFormShown = isJustShown;
      }
    });
  },
  methods: {
    isBorrower,
    async loadLoanable() {
      let loanableId = this.$route.query?.loanable_id ?? this.item?.loanable?.id;
      if (!loanableId) {
        console.error("Could not load loanable for new loan");
        this.$router.push(`/search/map`);
        return;
      }

      try {
        await this.$store.dispatch("loanables/tryRetrieveOne", {
          id: loanableId,
          params: {
            ressource: true,
          },
        });
      } catch (e) {
        // If not found, most likely user has no access
        this.$router.push("/app");
        return;
      }

      if (this.itemLoaded) {
        this.patchLoan();
      }
    },
    patchLoan() {
      const loanable = {
        ...this.$store.state.loanables.item,
      };

      // If this loan has been previously tested, add availability status to reloaded loanable.
      if (this.item.loanable) {
        loanable.available = this.item.loanable.available;
      }

      this.$store.commit("loans/patchItem", {
        borrower_user: this.user,
        loanable: this.$store.state.loanables.item,
        loanable_id: this.$store.state.loanables.item.id,
      });
    },
    skipLoadItem() {
      return this.id === "new" && this.$store.state.loans.item;
    },
    scrollToForm() {
      if (!this.loanFormShown) {
        this.$root.$emit("bv::toggle::collapse", "loan-actions-new");
      }
      this.$scrollTo(".loan-actions-creation");
    },
    scrollToActiveAction() {
      // Only do it once
      var called = false;
      var self = this;
      return (function () {
        if (
          called ||
          self.id === "new" ||
          !self.item.id ||
          self.loanIsCanceled ||
          self.loanIsCompleted
        ) {
          return;
        }
        self.$scrollTo(`.loan-actions-${self.currentStep}`);
        called = true;
      })();
    },
    async submitLoan() {
      await this.submit();
      await this.$store.commit("loanable.search/setLoanableAvailable", {
        id: this.item.loanable.id,
        available: false,
      });
      this.$store.dispatch("dashboard/loadLoans");
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        titles: locales.en.titles,
      },
      fr: {
        ...locales.fr.loans,
        titles: locales.fr.titles,
      },
    },
  },
};
</script>

<style lang="scss">
.loan.page {
  .loan_contribution_thanks {
    margin-bottom: 2rem;
    .awareness-banner__image {
      // Unset object-fit: cover which affects svgs weirdly.
      object-fit: unset;
    }
  }

  .page__content {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .loan-actions.loading {
    opacity: 0.5;
    pointer-events: none;
  }
  .actions-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
